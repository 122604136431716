import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContextProvider';
import { Button, Container, Modal, Form, Row, Col, Table } from 'react-bootstrap';
import bimg from '../../imgs/banner_background.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
import Notauthorized from '../../components/Notauthorized';

const ManokamnaDetails = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const manokamnaId = searchParams.get('manokamnaId');
  const [singleData, setSingleData] = useState();
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const { user: adminUser } = useContext(UserContext);
  const { permissioncontext } = location.state || {};

  const [formData, setFormData] = useState({
    date: new Date(new Date().setHours(0, 0, 0, 0)),
    notes: '',
  });
  const [errorsCompleteForm, setErrorsCompleteForm] = useState({});

  // for dropdown

  const [selectedOption, setSelectedOption] = useState('image');
  const [file, setFile] = useState('');
  const [fileError, setFileError] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [loading, setLoading] = useState(false)
  const [eventFile, setEventFile] = useState('');


  useEffect(() => {
    if (manokamnaId) {
      getDetailData();
    }
  }, [manokamnaId]);

  const getDetailData = async () => {
    if (!manokamnaId) return;

    try {
      const res = await APIServices.get(`mandirManokamna/manokamana-booking-details/${manokamnaId}`, headers);

      if (res?.status === 200 && res?.data?.status) {
        setSingleData(res.data.data);
      } else {
        console.error('Failed to fetch data:', res);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOptionChange = e => {
    setSelectedOption(e.target.value);
    setFileError(null);
    setFile('');
    setYoutubeLink('')
  };

  const handleReset = () => {
    setFormData({
      ...formData,
      date: new Date(new Date().setHours(0, 0, 0, 0)),
      notes: '',
    });
    setErrorsCompleteForm({});
    setSelectedOption('image');
    setDescription('')
    setYoutubeLink('');
    setFile('');
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    setEventFile(e.target.name)
    if (file) {
      const fileType = file.type;

      // Validate file type based on selected option
      if (selectedOption === 'image' && !['image/png', 'image/jpeg', 'image/webp', 'image/gif'].includes(fileType)) {
        setFileError('Only PNG, JPEG, WEBP, and GIF files are allowed for images.');
        toast.error('Only PNG, JPEG, WEBP, and GIF files are allowed for images.');
        return;
      }

      if (selectedOption === 'video' && !['video/mp4', 'video/webm', 'video/ogg'].includes(fileType)) {
        setFileError('Only MP4, WEBM, and OGG files are allowed for videos.');
        toast.error('Only MP4, WEBM, and OGG files are allowed for videos.');
        return;
      }

      if (selectedOption === 'audio' && !['audio/mpeg', 'audio/wav', 'audio/ogg'].includes(fileType)) {
        setFileError('Only MP3, WAV, and OGG files are allowed for audio.');
        toast.error('Only MP3, WAV, and OGG files are allowed for audio.');
        return;
      }

      // If no error, clear any previous file error and proceed
      setFileError(null);
      const url = URL.createObjectURL(file);
      setFile(file);
    }
  };

  const handleLinkChange = e => {
    setYoutubeLink(e.target.value);
  };

  // Validate the complete form fields

  const validateCompleteForm = () => {
    const newErrors = {};
    if (!formData.date) {
      newErrors.date = 'Date is required';
    }
    if (!formData.notes.trim()) {
      newErrors.notes = 'Notes is required';
    }
    setErrorsCompleteForm(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fileError) {
      toast.error('Please resolve the file error before submitting the form.');
      return;
    }
    if (!validateCompleteForm()) {
      return;
    }
    setLoading(true);
    const formDataN = new FormData();
    formDataN.append('manokamana_booking_id', singleData?.id);
    formDataN.append('admin_name', adminUser?.name);
    formDataN.append('admin_id', adminUser?._id);
    formDataN.append('recording_type', selectedOption);
    {
      (selectedOption === "image" || selectedOption === "video" || selectedOption === "audio") &&
        formDataN.append(eventFile, file)
    }
    // formDataN.append( eventFile ,file );
    formDataN.append('youtube_link', youtubeLink);
    formDataN.append('manokamana_time', moment(formData?.date).format('DD/MM/YYYY h:mm A'));
    formDataN.append('notes', formData?.notes);

    // for (let [key, value] of formDataN.entries()) {
    //   console.log(`${key}:`, value);
    // }
    try {
      const newHeaders = {
        ...headers,
        "Content-Type": "multipart/form-data"
      };
      const response = await APIServices.post('mandirManokamna/completed', formDataN, newHeaders);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setShowCompleteModal(false);
        handleReset();
        getDetailData()
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong! Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = e => {
    setDescription(e.target.value);
    if (errors?.description) {
      setErrors(prevErrors => ({ ...prevErrors, description: '' }));
    }
  };

  const handleSubmitCancelButton = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!description?.trim()) {
      newErrors.description = 'Please enter cancel reason.';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});
    setLoading(true);

    const formData = new FormData();
    formData.append('manokamana_booking_id', singleData?.id);
    formData.append('admin_name', adminUser?.name);
    formData.append('admin_id', adminUser?._id);
    formData.append('cancelled_reason', description);

    try {
      const response = await APIServices.post('mandirManokamna/cancel', formData, headers);

      if (response.data.status === true) {
        toast.success(response?.data?.message);
        handleReset()
        setShowCancelModal(false)
        getDetailData()
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error('Something went wrong! Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitRefund = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('manokamana_booking_id', singleData?.id);
    formData.append('admin_name', adminUser?.name);
    formData.append('admin_id', adminUser?._id);

    try {
      const response = await APIServices.post('mandirManokamna/refund', formData, headers);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setShowRefundModal(false)
        handleReset()
        getDetailData()

      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {permissioncontext?.view ?
        <Container className='mt-4 box-shadow br-6 p-20 my-3'>
          <h3 className='text-primary mb-4'>Manokamana Booking</h3>
          <Row>
            <Col
              md={4}
            // style={{border:"2px solid red"}}
            >
              <div className='text-center'>
                <Link to={singleData?.temple_image ? singleData?.temple_image : bimg} target='_blank'>
                  <img
                    src={singleData?.temple_image ? singleData?.temple_image : bimg}
                    className='card-img-top'
                    alt='img'
                    style={{ width: '150px', height: '150px', borderRadius: '50%' }}
                  />
                </Link>
              </div>
              <div className='booking_id_wrapper'>
                <div className='d-flex justify-content-between'>
                  <p className='fw-bold mb-0'>Manokamana Id</p>
                  <p className='text-primary fw-bold'>{singleData?.id ? singleData?.id : 'N/A'}</p>
                </div>
                <div className='d-flex justify-content-between'>
                  <p className='fw-bold mb-0'>Temple Name</p>
                  <p className='text-primary fw-bold'>{singleData?.temple_name ? singleData?.temple_name : 'N/A'}</p>
                </div>
                <div className='d-flex justify-content-between'>
                  <p className='fw-bold mb-0'>Address</p>
                  <p className='text-primary fw-bold'>{singleData?.address ? singleData?.address : 'N/A'}</p>
                </div>
                <div className='d-flex justify-content-between'>
                  <p className='fw-bold mb-0'>Source</p>
                  <p className='text-primary text-capitalize fw-bold'>{singleData?.source ? singleData?.source : 'N/A'}</p>
                </div>
              </div>
            </Col>
            <Col md={8} className='ps-3 ps-md-5'>
              <Row>
                <Col md={6} lg={6} xl={4}>
                  <div>
                    <p className='fw-bold'>User Name</p>
                    <p className='chadhawa_manokamna_details_para'>
                      {singleData?.user_name ? singleData?.user_name : 'N/A'}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <div>
                    <p className='fw-bold'>User Email</p>
                    <p className='chadhawa_manokamna_details_para'>
                      {singleData?.user_email ? singleData?.user_email : 'N/A'}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <div>
                    <p className='fw-bold'>User Mobile</p>
                    <p className='chadhawa_manokamna_details_para'>
                      {singleData?.user_mobile ? singleData?.user_mobile : 'N/A'}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <div>
                    <p className='fw-bold'>Manokamana Name</p>
                    <p className='chadhawa_manokamna_details_para'>
                      {singleData?.chadawa_name ? singleData?.chadawa_name : 'N/A'}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <div>
                    <p className='fw-bold '>Amount</p>
                    <p className='chadhawa_manokamna_details_para'>
                      {singleData?.amount === '0' ? 'Free' : singleData?.currency_symbol + singleData?.amount || 'Free'}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <div>
                    <p className='fw-bold'>Transaction Id</p>
                    <p className='chadhawa_manokamna_details_para'>
                      {singleData?.transaction_id ? singleData?.transaction_id : 'N/A'}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <div>
                    <p className='fw-bold'>Status</p>
                    <p
                      className={`${singleData?.status === 'processing'
                        ? 'text-warning fw-bold chadhawa_manokamna_details_para'
                        : singleData?.status === 'completed'
                          ? 'text-success fw-bold chadhawa_manokamna_details_para'
                          : singleData?.status === 'cancelled'
                            ? 'text-danger fw-bold chadhawa_manokamna_details_para'
                            : ''
                        }`}
                    >
                      {singleData?.status === 'processing'
                        ? 'Processing'
                        : singleData?.status === 'completed'
                          ? 'Completed'
                          : singleData?.status === 'cancelled'
                            ? 'Cancelled'
                            : '_'}
                    </p>
                  </div>
                </Col>
                <Col md={6} lg={6} xl={4}>
                  <div>
                    <p className='fw-bold'>Created At</p>
                    <p className='chadhawa_manokamna_details_para'>
                      {/* {singleData?.created_at ? moment(singleData.created_at).format('DD MMM, YYYY hh:mm A') : 'N/A'} */}
                      {singleData?.created_at 
                           ? moment.utc(singleData.created_at).local().format("DD MMM, YYYY hh:mm A") 
                       : 'N/A'}
                    </p>
                  </div>
                </Col>
                {singleData?.completed_date && (
                  <Col md={6} lg={6} xl={4}>
                    <div>
                      <p className='fw-bold'>Completed Date</p>
                      <p className='chadhawa_manokamna_details_para'>
                        {singleData?.completed_date ? moment(singleData?.completed_date).format('DD MMM, YYYY') : 'N/A'}
                      </p>
                    </div>
                  </Col>
                )}
                {singleData?.manokamana_time && (
                  <Col md={6} lg={6} xl={4}>
                    <div>
                      <p className='fw-bold'>Manokamana Time</p>
                      <p className='chadhawa_manokamna_details_para'>
                        {singleData?.manokamana_time
                          ? moment(singleData?.manokamana_time, 'DD/MM/YYYY hh:mm A').format('DD/MM/YYYY hh:mm A')
                          : 'N/A'}
                      </p>
                    </div>
                  </Col>
                )}
                {singleData?.recording_type && singleData?.recording && (
                    <Col md={6} lg={6} xl={4}>
                      <div>
                        <p className='fw-bold'>Recording Type</p>
                        <p className='text-capitalize chadhawa_manokamna_details_para'>({singleData?.recording_type})</p>
                        {/* <Link to={singleData?.recording} target='_blank'>
                      Please click here to view
                    </Link> */}
                        <button
                          className={!permissioncontext?.add ? 'as_btn disabled-btn ms-0 mb-2 ps-3 pe-3' : 'as_btn ms-0 ps-3 pe-3 mb-2'}
                          disabled={!permissioncontext?.add}
                          onClick={() => {
                            if (permissioncontext.add) {
                              window.open(singleData?.recording, '_blank');
                            }
                          }}
                        >
                          View Document
                        </button>
                      </div>
                    </Col>            
                )}
                {singleData?.notes && (
                  <Col md={6} lg={6} xl={4}>
                    <div>
                      <p className='fw-bold'>Notes</p>
                      <p className='chadhawa_manokamna_details_para'>{singleData?.notes ? singleData?.notes : 'N/A'}</p>
                    </div>
                  </Col>
                )}
                {singleData?.status === 'cancelled' && (
                  <Col md={6} lg={6} xl={4}>
                    <div>
                      <p className='fw-bold'>Cancelled Date</p>
                      <p className='chadhawa_manokamna_details_para'>
                        {singleData?.cancelled_date
                          ? moment(singleData.cancelled_date).format('DD MMM, YYYY hh:mm A')
                          : 'N/A'}
                      </p>
                    </div>
                  </Col>
                )}
                {singleData?.refund_status && (
                  <Col md={6} lg={6} xl={4}>
                    <div>
                      <p className='fw-bold'>Refund Status</p>
                      <p className={singleData?.refund_status === 'completed' ? 'text-success fw-bold text-capitalize chadhawa_manokamna_details_para' :
                        singleData?.refund_status === 'pending' ? 'text-dark fw-bold text-capitalize chadhawa_manokamna_details_para' :
                          'chadhawa_manokamna_details_para'}>
                        {singleData?.refund_status}
                      </p>
                    </div>
                  </Col>
                )}
                {singleData?.refund_status === 'completed' && (
                  <Col md={6} lg={6} xl={4}>
                    <div>
                      <p className='fw-bold'>Refund Date</p>
                      <p className='chadhawa_manokamna_details_para'>
                        {singleData?.refunded_date
                          ? moment(singleData.refunded_date).format('DD MMM, YYYY hh:mm A')
                          : 'N/A'}
                      </p>
                    </div>
                  </Col>
                )}
                {singleData?.cancelled_reason && (
                  <Col md={4}>
                    <div>
                      <p className='fw-bold'>Cancel Reason</p>
                      <p className='chadhawa_manokamna_details_para'>
                        {singleData?.cancelled_reason ? singleData?.cancelled_reason : 'N/A'}
                      </p>
                    </div>
                  </Col>
                )}

              </Row>
              <div className='col-12'>
                {/* {singleData?.chadawa_samgri_arr && singleData?.chadawa_samgri_arr?.length > 0 ? (
                  <>
                    <h3 className='fw-bold mt-3 mb-2'>Manokamana Samgri Details</h3>
                    <div className='d-flex flex-wrap'>
                      {singleData?.chadawa_samgri_arr &&
                        singleData?.chadawa_samgri_arr?.length > 0 &&
                        singleData?.chadawa_samgri_arr.map((item, ind) => {
                          return (
                            <>
                              <div className='text-center me-3'>
                                <img
                                  src={item?.image}
                                  className='card-img-top'
                                  alt='img'
                                  style={{ width: '150px', height: '150px', borderRadius: '5%', objectFit:"contain" }}
                                />
                                <p className='fw-bold'>{item?.samgri_name}</p>
                                <p className='chadhawa_manokamna_details_para'>
                                  {item?.currency_symbol}
                                  {item?.price}
                                </p>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  ''
                )} */}


                {/* <div className='mt-5'>
              {singleData?.status === 'processing' && 
                  <button
                  //  className='as_btn bg-danger' 
                  disabled={!permissioncontext.delete}
                  className={!permissioncontext.delete ? 'as_btn bg-danger disabled-btn':'as_btn bg-danger'}
                  onClick={() => setShowCancelModal(true)}>
                    Cancel
                  </button>
                }
                {singleData?.status=='cancelled' && singleData?.refund_status=='pending' && singleData?.amount > 0 &&
                  <button 
                  // className='as_btn bg-success'
                  disabled={!permissioncontext.edit}
                  className={!permissioncontext.edit ? 'as_btn bg-success edit-btn1 disabled-btn ps-3 pe-3' : "as_btn bg-success ps-3 pe-3"} 
                onClick={() => setShowRefundModal(true)}>
                    Refund
                  </button>
                }
                {(singleData?.status === 'processing') && 
                <button 
                    disabled={!permissioncontext.add}
                    className={!permissioncontext.add ? 'as_btn bg-success disabled-btn ps-3 pe-3':'as_btn bg-success ps-3 pe-3'}
                    onClick={() => setShowCompleteModal(true)}>
                  Complete
                </button>
                  }
                <button className='as_btn text-black border-danger ps-3 pe-3' onClick={() => navigate(-1)}>
                  Back
                </button>
              </div> */}
              </div>

                
              <Row>
                  <Col md={12} className="ps-3">
                    <div>
                      {singleData?.chadawa_samgri_arr && singleData?.chadawa_samgri_arr.length > 0 && 
                        <>
                          <h3 className="fw-bold mt-3 mb-2">Manokamana Samgri Details</h3>
                          <Table bordered responsive >
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Image</th>
                                <th>Samgri Name</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total Price</th>
                              </tr>
                            </thead>
                            <tbody >
                              {singleData?.chadawa_samgri_arr.map((item, ind) => (
                                <tr key={ind}  valign='middle' align='center'>
                                  <td>{ind + 1}</td>
                                  <td>
                                    <img
                                      src={item?.image}
                                      alt="img"
                                      style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                                    />
                                  </td>
                                  <td>{item?.samgri_name}</td>
                                  <td>{Number(item?.qty) || 1}</td>
                                  <td>
                                    {item?.currency_symbol}
                                    {item?.price}
                                  </td>
                                  <td>
                                    {item?.currency_symbol}
                                    {Number(item?.price) * (Number(item?.qty) || 1)}
                                  </td>
                                  {/* <td>{item?.currency_symbol}{item?.qty ? (Number(item?.price) * Number(item?.qty)) : (item?.price * Number(1))}</td> */}
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={2} className="fw-bold">Total Amount</td>
                                <td colSpan={3} className="fw-bold"></td>
                                <td colSpan={1} className="fw-bold text-center">
                                {singleData?.amount ? singleData?.currency_symbol + singleData?.amount : 'Free'}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      }
                    </div>
                  </Col>
                  
                </Row>
            </Col>
          </Row>

          <div className='mt-3 text-center'>
            {singleData?.status === 'processing' &&
              <button
                //  className='as_btn bg-danger' 
                disabled={!permissioncontext.delete}
                className={!permissioncontext.delete ? 'as_btn bg-danger disabled-btn' : 'as_btn bg-danger'}
                onClick={() => setShowCancelModal(true)}>
                Cancel
              </button>
            }
            {singleData?.status == 'cancelled' && singleData?.refund_status == 'pending' && singleData?.amount > 0 &&
              <button
                // className='as_btn bg-success'
                disabled={!permissioncontext.edit}
                className={!permissioncontext.edit ? 'as_btn bg-success edit-btn1 disabled-btn ps-3 pe-3' : "as_btn bg-success ps-3 pe-3"}
                onClick={() => setShowRefundModal(true)}>
                Refund
              </button>
            }
            {(singleData?.status === 'processing') &&
              <button
                disabled={!permissioncontext.add}
                className={!permissioncontext.add ? 'as_btn bg-success disabled-btn ps-3 pe-3' : 'as_btn bg-success ps-3 pe-3'}
                onClick={() => setShowCompleteModal(true)}>
                Complete
              </button>
            }
            <button className='as_btn border-danger ps-3 pe-3' onClick={() => navigate(-1)}>
              Back
            </button>
          </div>

          {/* cancel modal */}
          <Modal
            centered
            scrollable
            show={showCancelModal}
            size='md'
            aria-labelledby='example-modal-sizes-title-sm'
            backdrop='static'
            onHide={() => {
              setShowCancelModal(false);
              handleReset();
              setErrors({});
            }}
          >
            <Modal.Header className='modal-header-hd' closeButton>
              <Modal.Title>Cancel Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group as={Row} className='mb-3' controlId='formBasicTitle'>
                  <Form.Label className='required' column sm='3'>
                    Cancel Reason :
                  </Form.Label>
                  <Col sm='9'>
                    <Form.Control
                      type='text'
                      as='textarea'
                      style={{ height: 100 }}
                      placeholder='Cancel Reason...'
                      name='description'
                      value={description}
                      onChange={handleChange}
                      isInvalid={!!errors?.description}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.description}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                className='close_btn as_btn reset-btn'
                onClick={() => {
                  setShowCancelModal(false);
                  handleReset();
                  setErrors({});
                }}
              >
                Close
              </Button>
              <Button type='submit' className='as_btn' onClick={e => handleSubmitCancelButton(e)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>

          {/* refund modal */}
          <Modal
            centered
            scrollable
            show={showRefundModal}
            size='md'
            aria-labelledby='example-modal-sizes-title-sm'
            backdrop='static'
            onHide={() => {
              setShowRefundModal(false);
              handleReset();
            }}
          >
            <Modal.Header className='modal-header-hd' closeButton>
              <Modal.Title>Refund</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Are you sure to refund this manokamana amount ?</h4>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                className='close_btn as_btn reset-btn'
                onClick={() => {
                  setShowRefundModal(false);
                  handleReset();
                }}
              >
                Close
              </Button>
              <Button
                type='submit'
                className='as_btn'
                disabled={loading}
                onClick={e => handleSubmitRefund(e)}
              >
                {loading ? 'loading...' : 'Confirm'}
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Complete modal */}
          <Modal
            centered
            scrollable
            show={showCompleteModal}
            size='md'
            aria-labelledby='example-modal-sizes-title-sm'
            backdrop='static'
            onHide={() => {
              setShowCompleteModal(false);
              handleReset();
            }}
          >
            <Modal.Header className='modal-header-hd' closeButton>
              <Modal.Title>Completed Manokamna</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group as={Row} controlId='formDate'>
                  <Form.Label column sm='3' className='required'>
                    Mandir Chadawa Time
                  </Form.Label>
                  <Col sm='9'>
                    <DatePicker
                      selected={formData?.date}
                      onChange={date => {
                        setFormData({ ...formData, date });
                        if (date) {
                          setErrorsCompleteForm(prev => ({ ...prev, date: null }));
                        }
                      }}
                      timeInputLabel='Time:'
                      dateFormat='dd/MM/yyyy h:mm aa'
                      showTimeInput
                      className='form-control'
                      maxDate={new Date()}
                    />
                    {errorsCompleteForm?.date && <Form.Text className='text-danger'>{errorsCompleteForm?.date}</Form.Text>}
                  </Col>
                </Form.Group>

                {/* Notes Field */}
                <Form.Group as={Row} controlId='formDescription' className='mt-4'>
                  <Form.Label column sm='3' className='required'>
                    Notes
                  </Form.Label>
                  <Col sm='9'>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      style={{ height: 80 }}
                      name=''
                      value={formData?.notes}
                      onChange={e => {
                        setFormData({ ...formData, notes: e.target.value });
                        if (e.target.value.trim() !== '') {
                          setErrorsCompleteForm(prev => ({ ...prev, notes: null }));
                        }
                      }}
                      placeholder='Notes...'
                      isInvalid={!!errorsCompleteForm?.notes}
                    />
                    <Form.Control.Feedback type='invalid'>{errorsCompleteForm?.notes}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId='mediaTypeSelect' className='mb-3 mt-4'>
                  <Form.Label column sm='3'>
                    Type
                  </Form.Label>
                  <Col sm='9'>
                    <Form.Select value={selectedOption} onChange={handleOptionChange}>
                      <option value='image'>Image</option>
                      <option value='video'>Video</option>
                      <option value='audio'>Audio</option>
                      <option value='youtube'>YouTube Link</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                {selectedOption === 'image' && (
                  <Form.Group as={Row} controlId='formFileImage' className='mt-3'>
                    <Form.Label column sm='3'>
                      Select an Image
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        type='file'
                        name='file'
                        // accept='image/png, image/gif, image/jpeg, image/webp'
                        onChange={handleFileChange}
                      />
                    </Col>
                  </Form.Group>
                )}

                {selectedOption === 'video' && (
                  <Form.Group as={Row} controlId='formFileVideo' className='mt-3'>
                    <Form.Label column sm='3'>
                      Select a Video
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        type='file'
                        name='video'
                        //  accept='video/*'
                        onChange={handleFileChange}
                      />
                    </Col>
                  </Form.Group>
                )}

                {selectedOption === 'audio' && (
                  <Form.Group as={Row} controlId='formFileAudio' className='mt-3'>
                    <Form.Label column sm='3'>
                      Select an Audio
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        type='file'
                        name='audio'
                        //  accept='audio/*'
                        onChange={handleFileChange}
                      />
                    </Col>
                  </Form.Group>
                )}

                {selectedOption === 'youtube' && (
                  <Form.Group as={Row} controlId='formYoutubeLink' className='mt-3'>
                    <Form.Label column sm='3'>
                      Enter YouTube Link
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        type='url'
                        placeholder='Paste YouTube link here'
                        value={youtubeLink}
                        onChange={handleLinkChange}
                      />
                    </Col>
                  </Form.Group>
                )}


                {/* Submit Button */}
                {/* <Button variant='primary' type='submit'>
                Submit
              </Button> */}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button type='submit' className='as_btn' onClick={e => handleSubmit(e)}>
                {loading ? 'Loading' : 'Submit'}
              </Button>
              <Button
                variant='secondary'
                className='close_btn as_btn reset-btn'
                onClick={() => {
                  setShowCompleteModal(false);
                  handleReset();
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {/* <ToastContainer /> */}
        </Container>
        :
        <Notauthorized />
      }
    </>
  );
};

export default ManokamnaDetails;

//https://reactdatepicker.com/  -- Input time
