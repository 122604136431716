import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { FaEdit, FaTrash, FaBell, FaArrowLeft, FaSistrix } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import APIServicenew from '../../utils/APIGeneralService';
import { UserContext } from '../../context/UserContextProvider';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import moment from 'moment';
import { formateDate, setCookies } from '../../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';

const CouponDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const couponId = searchParams.get('couponId');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const { refreshToken } = useContext(UserContext);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [flag, setFlag] = useState(true);
  const [name, setName] = useState('');
  const [appliedOn, setAppliedOn] = useState('');
  const [number, setNumber] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [countData, setCountData] = useState()

  useEffect(() => {
    handleCountData()
    if (flag === true) {
    getCouponData(skip, limit, pageIndex);
    }
   }, [flag]);

  const getCouponData = useCallback(
    async (skip, limit, index) => {
      try {
        const response = await APIServices.get(
          `couponMaster/getCoupanHistoryById/${couponId}/${skip}/${limit}?name=${name}&appliedOn=${appliedOn}&number=${number}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount);
          setTableData(response.data?.Listdata);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, name, appliedOn, number]
  );
  const handleCountData = async (id) => {
    const res = await APIServices.get(`couponMaster/getcoupanCount?couponId=${couponId}`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };
  const handleFilterClick = () => {
    if (skip || name || appliedOn || number) {
      getCouponData(skip, limit, 0, name, appliedOn, number);
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'User Name / Contact Number',
      Cell: e => {
        return (
          <span>{`${e.row.original?.userData.name ?? '_'} / ${e.row.original?.userData.contactNumber ?? '_'}`}</span>
        );
      },
    },
    {
      Header: 'Puja Name',
      Cell: e => {
        return <span> {e.row.original.pujaName ?? '_'}</span>;
      },
    },
    {
      Header: 'Applied On',
      Cell: e => {
        return <span> {e.row.original.appliedOn ?? '_'}</span>;
      },
    },
    {
      Header: 'Coupon Value',
      Cell: e => {
        return (
          <span>
            {' '}
            {e.row.original.type === 'percent' ? e.row.original.value + '%' : e.row.original.value + 'Flat' ?? '_'}
          </span>
        );
      },
    },
    {
      Header: 'Coupon Discount',
      Cell: e => {
        return <span> {e.row.original?.currencySymbol + e.row.original.couponAmount ?? '_'}</span>;
      },
    },
    {
      Header: 'Total Amount',
      Cell: e => {
        return <span>{e.row.original?.currencySymbol + e.row.original.totalAmount ?? '_'}</span>;
      },
    },
    {
      Header: 'Updated Date',
      Cell: e => {
        return <span>{moment(e.row.original.updatedAt).format('DD MMM, YYYY hh:mm A')}</span>;
      },
    },
  ]);

  const handleReset = () => {
    setName('');
    setNumber('');
    setAppliedOn('');
    setFlag(true);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };
  return (
    <div>
      <div className='main aarti_section'>
        <>
          <div className='gita_quote_heading'>
            <div className='container d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q'>
                <FaArrowLeft
                  className='hd-cr '
                  style={{ marginRight: '15px' }}
                  size={25}
                  onClick={() => navigate(-1)}
                />
                <span style={{ textTransform: 'capitalize' }}>Coupon History ({allDataCount})</span>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Coupon Discount <small>{countData?.totalCouponDiscountByCoupanId}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Applied On Total Amount  <small>{countData?.totalAmountByCoupanId}</small>
                </h4>
            </div>
          </div>
        </>
        <div className='user-list-table'>
          <div className='feed_area box-shadow br-6 p-20 my-3 font_16'>
            <div className='chat_search_box user-search'>
              <div className='chat-search-field'>
                <Form>
                  <Form.Group as={Row} className='' controlId=''>
                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className='label-f-w-cd'>User Name</Form.Label>

                      <Form.Control
                        className=' mt-1 form-ch'
                        name='name'
                        type='text'
                        placeholder='Search By Name'
                        value={name}
                        onChange={e => {
                          setName(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label className='label-f-w-cd'>Contact Number</Form.Label>

                      <Form.Control
                        className=' mt-1 form-ch'
                        name='number'
                        type='search'
                        placeholder='Search By Contact Number'
                        value={number}
                        onChange={e => {
                          setNumber(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className='label-f-w-cd'>Applied On</Form.Label>
                      <Form.Select
                        aria-label='categories'
                        className='  astroWatch-select-hover mt-1 hd-cr form-ch'
                        value={appliedOn}
                        onChange={e => {
                          setAppliedOn(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value=''>Select</option>
                        <option value='puja '>Puja</option>
                        <option value='report'>Report</option>
                        <option value='chat'>Chat</option>
                        <option value='call'>Call</option>
                        <option value='chadhawa'>Chadhawa</option>
                        <option value='ask_question'>Ask Question</option>
                      </Form.Select>
                    </Col>
                    <Col md={3} className='mt-3'>
                      <Button
                        variant='secondary'
                        className='as_btn ms-0'
                        onClick={() => {
                          handleFilterClick();
                          setSkip(0);
                        }}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='secondary'
                        className='as_btn reset-btn'
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={getCouponData}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className='no_records'>Records not Available</h2>
        )}
      </div>
    </div>
  );
};

export default CouponDetails;
