import { useEffect, useState ,useContext} from "react";
import { Button, Modal, Row, Form, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import bimg from "../../src/imgs/default.jpg";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import CropModal from "../components/Shared/CropModal";
import { getBaseURL ,setCookies} from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

import usePermissionData from "../components/Customhook/custompermission";
import Notauthorized from "../components/Notauthorized";

const  InspiringQuotes =  () =>{
  document.title = 'InspiringQuotes';
    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)
    const permissioncontext =usePermissionData("inspiringQuotes")

    const headers = { Authorization: `Bearer ${token}` };
    let astroUserId = cookies.get("astroUserId");
    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(12);
    const [pageCount, setPageCount] = useState(0);
    const [len, setLen] = useState();
    const [loading, setLoading] = useState();
    const [imageType, setImageType] = useState("hindi");
    const [allData , setAllData] = useState([])
    const [ contentType , setContentType] =  useState("")
    const [ file , setFile] = useState ("");
    const [ image , setImage] = useState("");
    const [ imgShow , setImgShow] = useState("");
    const [show, setShow] = useState(false);
    const [showup, setShowUp] = useState(false);
    const [showdt, setShowDt] = useState(false);
    const [data, setData] = useState();
    const [selectedId, setSelectedId] = useState();
    const [status , setStatus] = useState("all")
    const [errors, setErrors] = useState({});
    const [showcrop, setShowCrop] = useState(false);
    const [cropimg, setCropImg] = useState("");
    const [caartifileimage,setFileCaartiimage]=useState()
    const [loader,setLoader]=useState(false)
    const [allDataCount , setAllDataCount] = useState()
    const  {refreshToken} = useContext(UserContext);
    const navigate = useNavigate();
    const [selectedIds ,setSelectedIds] = useState([])
    const [ flag , setFlag] = useState();
    const [ flag1 , setFlag1] = useState(false)

    const logout =() =>{
      refreshToken(null)
      setCookies("accessToken", null);
      navigate("/");
    }
      const handleClose = () => 
      {setShow(false)
        setErrors("")
      };

      const handleCloseDt = () => setShowDt(false);
    
      const handleShow = async(item) => {
        setShow(true);
        setImage("")
        setFile("")
        setSelectedId(item?._id);
        setContentType(item?.contentEn === true ?"english" : item?.contentHi === true ? "hindi" :"")
        setImgShow(item?.file);
      };
      
      const handleShowDt = (id) => {
        setSelectedId(id);
        setShowDt(true);
      };

      const fetchData = async () => {
         setLoading(true);
       
      let response = await APIServices.get(`inspiringQuotes/getAll/${imageType}/${offset}/${perPage}?status=${status}`, headers);
      let data = response?.response?.data
      if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status) {
            setData(apiData?.Listdata);
            setAllDataCount(apiData.totalCount)
            setFlag1(false)
            setLoading(false);
          }
        
    }else{
      if(data?.status == 4){
        logout()
       }
    }
      };

      async function updateFile(showToast) {
        let res = await APIServices.get(`inspiringQuotes/updateFile`, headers);
        if (res?.status === 200) {
          let apiData = res?.data;
          if (apiData?.status) {
            if (showToast) {
              toast.success(apiData?.message);
              
            }
            fetchData();
          }
        }
      }
      const multidelete = () => {
        if (flag === true) {
         setShowDt(true)
        }else{
         toast.warn("Please select cards for delete");
        }
       }
     
       const handleMultiDt = (e ,id) => {
         let checked = e.target.checked 
         if(checked === true){
           setFlag(true)
           setSelectedIds(current => [...current, id]);
         }else if(checked === false){
           setSelectedIds(current => current.filter((ls)=> ls !== id))
         }
       }
     
       useEffect(()=>{
         if (selectedIds?.length === 0) {
           setFlag(false)
         }
       },[selectedIds])
     
       const handleMultidelete = async () => {
     
         let payload = {Ids: selectedIds}
         let res = await APIServices.delete(`inspiringQuotes/multiDelete`, headers ,payload)
         if (res?.status === 200) {
           setShowDt(false)
           setOffset(0)
           let apiData = res?.data;
           if (apiData?.status) {
             toast.success(apiData?.message);
             setSelectedIds([])
             setFlag(false)
             fetchData()
           }
         }
       }
       const multiActivecheck = () => {
        if (flag === true) {
         multiActive()
        }else{
         toast.warn("Please select records for Active");
        }
       }
    
       const multiActive = async () => {
        let payload = {Ids: selectedIds,status:true}
    
        let res = await APIServices.put(`inspiringQuotes/updateMulitStatus`,payload, headers )
        if (res?.status === 200) {
           
          
          
          let apiData = res?.data;
          if (apiData?.status) {
            toast.success(apiData?.message);
            fetchData()
            setSelectedIds([])
            setFlag(false)
            
          }
        }
      }
    
    
       const multiInactivecheck = () => {
        if (flag === true) {
        multiInactive()
        }else{
         toast.warn("Please select records for Inactive");
        }
       }
    
    
       const multiInactive = async () => {
        let payload = {Ids: selectedIds,status:false}
    
        let res = await APIServices.put(`inspiringQuotes/updateMulitStatus`,payload, headers )
        if (res?.status === 200) {
           
          
          
          let apiData = res?.data;
          if (apiData?.status) {
            toast.success(apiData?.message);
            fetchData()
            setSelectedIds([])
            setFlag(false)
            
          }
        }
      }
    
      const validateInputs = () => {
        let isValid = false;
       
        const newErrors = {};
    
        if (file === "" && (!imgShow || (imgShow && imgShow.length === 0))) 
        newErrors.file = "Please add single or multi images.";
        let maxLength = 10
 
        if (image && (image.length > maxLength && !selectedId)) 
        newErrors.file = "Images can't be more then 10";
    
          if (!contentType ||contentType === "") 
          newErrors.imageType = "Please enter image type."; 
    
          return newErrors;
      }; 
      const handlePageClick = (e) => {
        const selectedPage = e.selected;
        console.log(selectedPage * perPage);
        const newOffset = (selectedPage * perPage);
        setOffset(newOffset);
      };

          async function handleEdit() {
        const formData = new FormData();
        if (file) {
          formData.append("file", file[0]);
        }
        if (contentType === "english") {
          formData.append("contentEn", true);
          formData.append("contentHi", false);
    
        } else if (contentType === "hindi") {
          formData.append("contentHi", true);
          formData.append("contentEn", false);
        }
        formData.append("userId", astroUserId);
    
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
          let response = await APIServices.put(
            `inspiringQuotes/editOne/${selectedId}`,
            formData,
            headers
          );
          if (response?.status === 200) {
            let apiData = response?.data;
            if (apiData?.status === true) {
              toast.success(apiData?.message);
              setFile("");
              setShow(false);
              setLoader(false)
              updateFile();
              setCropImg()
            }else if(apiData?.status === false){
              toast.error(apiData?.message);
              setLoader(false)
            }
          }
          setLoader(false)
        }
      }
      
      
      async function handleCreate() {

        const newErrors = validateInputs();
        if(Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          setLoader(false)
        }else {
        let i = 0;
        for (i;i< file.length;i++) {
        const formData = new FormData();
    
        formData.append("file", file[i]);
        if (contentType === "english") {
          formData.append("contentEn", true);
    
        } else if (contentType === "hindi") {
          formData.append("contentHi", true);
        }
        formData.append("userId", astroUserId);
    
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };
        setLoader(true)
      
          let response = await APIServices.post(
            `inspiringQuotes/upload`,
            formData,
            headers
          );
          if (response?.status === 200) {
            let apiData = response?.data;
            if (apiData?.status === true) {
              if(i === file.length -1){
                setShow(false);
                toast.success(apiData?.message);
                updateFile();
                setLoader(false)
              }
            }else if(apiData?.status === false){
              toast.error(apiData?.message);
              setLoader(false)
            }
          }
          setLoader(false)
        }
      }
      };
      
      const handleclick = async (status, id) => {
        var datas = status === true ? false : true;
        const formData = new FormData();
        formData.append("status", datas);
        await APIServices.put(`inspiringQuotes/updateStatus/${id}`, formData, headers)
          .then((res) => {
            updateFile()
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const handledelete = async () => {
        let res = await APIServices.delete(`inspiringQuotes/findByIdAndremove/${selectedId}`, headers);
        if (res?.status === 200) {
          let apiData = res?.data;
          setShowDt(false);
          setOffset(0)
          if (apiData?.status) {
            toast.success(apiData?.message);
            updateFile();
          }
        }
      };
      useEffect(()=>{
        fetchData()
       },[flag1])
  
      useEffect(() => {
        fetchData();
      }, [offset ,imageType ,status]);

      function handleChange(e) {
        let key, value;
        key = e?.target?.name;
        if (!!errors[key]){
          setErrors({
            ...errors,
            [key]: null,
            });
        }
      }
    
      const onImageChange = (event) => {
      var fileObj = [];
      var arrobj = [];
      const allowedType = "image/webp";
      const maxSize = 300 * 1024;

      if (event.target.files && event.target?.files) {
        if (event.target.files.length > 10) {
          alert("You can only select up to 10 images.");
          return;
        }
    
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
    
          if (file.type !== allowedType) {
            toast.error("Only WEBP files are allowed.");
            return;
          }
    
          if (file.size > maxSize) {
            toast.error(`File "${file.name}" exceeds the maximum size of 300KB.`);
            return;
          }
        }
        setFile(event.target.files);
        fileObj.push(event.target.files);
        for (let i = 0; i < fileObj[0]?.length; i++) {
          arrobj?.push(URL.createObjectURL(fileObj[0][i]));
        }
        setImage(arrobj);
      }
      let key, value;
      key = event?.target?.name;
      if (!!errors[key]){
        setErrors({
          ...errors,
          [key]: null,
          });
      }
    };


const renderFormModal = () => {
  return(
    <Modal centered scrollable show={show} backdrop="static" onHide={()=>{handleClose(); setCropImg()}}>
    <Modal.Header closeButton>
      <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Image`}</Modal.Title>

    </Modal.Header>
    <Modal.Body>
      <Form>
      {imgShow? 
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="" column sm="3">
                Previous
              </Form.Label>
              <Col sm="9">
             <img
               src={imgShow}
               width="20%"
               alt=""
             />
         
              </Col>
            </Form.Group>
                    : ""}
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className={`${selectedId?"": "required"}`} column sm='3'>
                Image
              </Form.Label>
              <Col sm='9'>
              {selectedId ?
              <Form.Control
                type="file"
                name="file"
                isInvalid={!!errors?.file}
                className="form-control "
                onChange={onImageChange}
                size="40"
                accept='image/webp'
                // accept="image/png, image/gif, image/jpeg"
               />:<Form.Control
                type="file"
                name="file"
                isInvalid={!!errors?.file}
                className="form-control "
                onChange={onImageChange}
                size="40"
                accept='image/webp'
                // accept="image/png, image/gif, image/jpeg"
                multiple
             />}
              <Form.Control.Feedback type="invalid">
                {errors?.file}
              </Form.Control.Feedback>
              {selectedId ?  <img
                          src={image[0]}
                          width="20%"
                          alt=""
                        />:
             image?.length === 0 ?"" :
             <div class="gallery">
             {image
                ? image?.map((item, key) => (
                    <img width="60px" height="60px" src={item} alt="" />
                  ))
              : ""}
             </div>}
             {selectedId?"":<small>Maximum Limit 10</small>}
            </Col>
            </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="required" column sm="3">
            Image Type
          </Form.Label>
          <Col sm="9">
            <Form.Select
              aria-label="Default select example"
              required
              className="hd-cr"
              name ="imageType"
              defaultValue={contentType}
              isInvalid={!!errors?.imageType}
              onChange={(e) => {setContentType(e.target.value);handleChange(e)}}
            >
              <option value="">Choose</option>
              <option value="english">English</option>
              <option value="hindi">Hindi</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors?.imageType}
              </Form.Control.Feedback>
          </Col>
          {/* <Col sm="4"></Col> */}
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
    {loader?<Button variant='primary' className='as_btn' >
    {`${selectedId ? 'Processing...' : 'Processing...'}`}
  </Button>:<Button variant='primary' className='as_btn' onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}>
  {`${selectedId ? 'Edit' : 'Add'}`}
</Button>}
      <Button variant="secondary" className="as_btn reset-btn" onClick={()=>{handleClose(); setCropImg()}}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  )
}


    return(
<>
{
  permissioncontext?.view ?
  <div className="main aarti_section">
  <div className="gita_quote_heading">
    <div className="container d-flex align-items-center justify-content-between">
      <h4 className="hd-list-f left-q">Inspiring Quotes <small>({allDataCount})</small></h4>
      <div className="_btn">
      <Button disabled={!permissioncontext.edit} variant='primary' className='by-btn as_btn' onClick={() => multiActivecheck()}>
          Multi Active
        </Button>
        <Button disabled={!permissioncontext.edit} variant='primary' className='by-btn as_btn' onClick={() => multiInactivecheck()}>
          Multi Inactive
        </Button>
        <Button
          disabled={!permissioncontext.add}
          variant="primary"
          className="by-btn as_btn"
          onClick={()=>updateFile(true)}
        >
          Update File
        </Button>
      
        <Button
          disabled={!permissioncontext.add}
          variant="primary"
          onClick={handleShow}
          className="by-btn as_btn me-0"
        >
          Add Image 
          </Button>&nbsp;&nbsp;
        <Button
          disabled={!permissioncontext.delete}
         className="by-btn as_btn me-0"
         style={{background:"red"}}
          variant="primary"
          onClick={() => {
            multidelete()
          }}
        >
          Multi Delete
          </Button>
      </div>
    </div>
  </div>
  <div className="aarti_inner pt-5">
    <div className="container">
      <div className="row">
        {/* <div className="col-md-4 col-lg-3">
          <Sidebar />
        </div> */}
        <div className="col-md-8 col-lg-12">
          {loading ? (
            <div className="center">
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              <div className="row">
                <div className="container">
                  <div className="d-flex align-items-center"  style={{justifyContent:"flex-end"}}>
                    <h4 className="hd-list-f left-q"></h4>
                    <Form.Select
                      aria-label="categories"
                      className=" w-25 astroWatch-select-hover hd-cr right-q"
                      value={status}
                      onChange={(e) => {setStatus(e.target.value);setOffset(0)}}
                    >
                      <option value="all">All</option>
                      <option value="active" >Active</option>
                      <option value= "inActive">InActive</option>
                    </Form.Select>
                    <Form.Select
                      aria-label="categories"
                      className=" w-25 astroWatch-select-hover hd-cr"
                       value={imageType}
                       onChange={(e) => {setImageType(e.target.value);setOffset(0)}}
                    >
                      <option value="hindi">Hindi</option>
                      <option value="english">English</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
          
              <div className="row mt-3">
                  {data?.map((item)=>
                   <div className="col-md-6 col-lg-3">
              <div className="card mb-4" key={item?._id}>
                <div class="card-header">
                  <img
                    // crossOrigin="anonymous"
                    src={(item?.file) ?item?.file : bimg}
                    className="card-img-top"
                    alt="img"
                  />
                    <div className=" icon1">
                              <input
                                  className="form-check-input border_checkbox_c"
                                  type="checkbox"
                                  name="languages"
                                  value="Javascript"
                                  id="flexCheckDefault"
                                  onChange={(e)=>
                                  { handleMultiDt( e ,item?._id)}
                                   
                                  }
                              /></div>
                  <div className="icon card-icons">
                      <FaEdit
                              className={permissioncontext.edit?"me-2 hd-cr":"me-2 hd-cr disabled-btn"}
                              size={20}
                              onClick={(e) => {
                              if(permissioncontext.edit)
                              handleShow(item);
                              }}
                          />
                    <FaTrash
                       className={permissioncontext.delete?"dt-btn hd-cr":"dt-btn hd-cr disabled-btn"}
                      size={20}
                      onClick={(e) => {
                          if(permissioncontext.delete)
                        handleShowDt(item?._id);
                      }}
                    />
                     {item.status === true ? (
                 <input
                   type="button"
                   className={!permissioncontext.edit?"_active  disabled-btn":'_active '} 
                            disabled={!permissioncontext.edit}
                      style={{fontSize:"8px", borderRadius:"15px"}}
                   value="Active"
                   onClick={() =>
                     handleclick(item.status, item._id)
                   }
                 />
               ) : (
                 <input
                   type="button"
                   className={!permissioncontext.edit?"_inactive  disabled-btn":'_inactive '} 
                            disabled={!permissioncontext.edit}
                   style={{fontSize:"8px", borderRadius:"15px"}}
                   value="Inactive"
                   onClick={() =>
                     handleclick(item.status, item._id)
                   }
                 />
               )}
                  </div>
                </div>
              </div>
            </div>)}
              
            </div>
         
            
            </>
          )}
        <div className="pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          // pageCount={pageCount}
          pageCount={Math.ceil(allDataCount / perPage)}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
          forcePage={offset/perPage}
        />
      </div> 
        </div>
      </div>
    </div>
  </div>
</div>:
<Notauthorized />
}
       
      {renderFormModal()}
      <Modal
        show={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {flag === true?`Are you sure you want to delete ${selectedIds?.length} records?`:` Are you sure you want to delete this element?`}
          </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>

          <Button variant="primary" className="success_btn" onClick={flag === true?handleMultidelete:handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      <CropModal 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    caartifileimage={caartifileimage}
    setFileCaartiimage={setFileCaartiimage}/>
      </>
    )
}


export default  InspiringQuotes ;