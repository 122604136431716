import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Container, Form, Button, Row, Col, Modal } from 'react-bootstrap';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import { UserContext } from '../../context/UserContextProvider';
import moment from 'moment';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies, formateDate } from '../../utils/Helper';
import {  toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import {Eye } from 'react-bootstrap-icons';
import usePermissionData from '../../components/Customhook/custompermission';
import Notauthorized from '../../components/Notauthorized';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const CouponMaster = () => {
  document.title = 'Coupon Master';
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const permissioncontext = usePermissionData('couponMaster');
  const { refreshToken, menuItem } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(true);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [showdt, setShowdt] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [couponType, setCouponType] = useState('');
  const [globalCouponType, setGlobalCouponType] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRange, setSelectedRange] = useState("");
  const [countData, setCountData] = useState()
  
  const [expireDate, setExpireDate] = useState({
    expireDate: '',
  });

  const handleInputChange = date => {
    // const formattedDate = moment(date).format('YYYY-MM-DD');
    setExpireDate(prevState => ({ ...prevState, expireDate: date }));
  };

  // Created date

   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };


  const handleCloseDt = () => setShowdt(false);
  const handleShowDt = id => {
    setSelectedId(id?._id);
    setShowdt(true);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  const getEditaData = async data => {
      navigate(`/couponMasterAddEdit?editDataId=${data?._id}`);
  };

  const handleDelete = async () => {
    const response = await APIServices.delete(`couponMaster/findByIdAndremove/${selectedId}`, headers);
    if (response?.data?.status === true) {
      toast.success(response?.data.message);
      setShowdt(false);
      handleCloseDt();
      fetchReportList();
    } else {
      console.log('Something went Wrong');
    }
  };

  const handleCountData = async (id) => {
    const res = await APIServices.get(`couponMaster/getcoupanCount`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };
  const handleReset = () => {
    setTitle('');
    setStatus('');
    setCouponType('')
    setGlobalCouponType('')
    setFlag(true);
    setSelectedRange('');
    setStartDate('')
    setEndDate('')
    setCouponCode('')
    setExpireDate({
      expireDate: '',
    });
  };

  const handleStatusUpdate = async item => {
    const formData = new FormData();
    var datas = item?.status === true ? false : true;
    let id = item?._id;
    formData.append('status', datas);

    const res = await APIServices.put(`couponMaster/updateStatus/${id}`, formData, headers);
    if (res?.data?.status === true) {
      fetchReportList();
    } else {
      console.log('error..');
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    
    {
      Header: 'Title',
      Cell: e => {
        return <span>{e.row.original.title ?? '_'}</span>;
      },
    },
    {
      Header: 'Description',
      Cell: e => {
        return <span>{e.row.original.description ?? '_'}</span>;
      },
    },
    {
      Header: 'Coupon Code',
      Cell: e => {
        return <span>{e.row.original.couponCode ?? '_'}</span>;
      },
    },
    {
      Header: 'Coupon Type',
      Cell: e => {
        return <span>{e.row.original.couponType ?? '_'}</span>;
      },
    },
    {
      Header: 'Global Coupon Type',
      Cell: e => {
        return <span>{e.row.original.globalCouponType ?? '_'}</span>;
      },
    },
    {
      Header: 'Per User Limit',
      Cell: e => {
        return (
          <span>
            {e.row.original.perUserLimit ?? '_'}
          </span>
        );
      },
    },
    {
      Header: 'Total Limit',
      Cell: e => {
        return (
          <span>
            {e.row.original.totalLimit ?? '_'}
          </span>
        );
      },
    },
    {
      Header: 'Used Count',
      Cell: e => {
        return (
          <span>
            {e.row.original.usedCount ?? '_'}
          </span>
        );
      },
    },
    {
      Header: 'Status',
      Cell: e => {
        if (e.row.original.status === true) {
          return (
            <h5>
              <input
                type='button'
                className={
                  !permissioncontext.edit ? '_active disabled-btn' : 
                  '_active '}
                disabled={!permissioncontext.edit}
                value='Active'
                onClick={() => handleStatusUpdate(e.row.original)}
              />
            </h5>
          );
        } else {
          return (
            <h5>
              <input
                type='button'
                className={
                  !permissioncontext.edit ? '_inactive disabled-btn' : '_inactive '}
                disabled={!permissioncontext.edit}
                value='Inactive'
                onClick={() => handleStatusUpdate(e.row.original)}
              />
            </h5>
          );
        }
      },
    },
    {
      Header: 'Created At',
      Cell: e => {
        return (
          <>
            <span>
              {e.row.original.createdAt ? moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A') : '_'}
            </span>
          </>
        );
      },
    },
    {
      Header: 'Expiry Date',
      Cell: e => {
        return (
          <>
              <span>
                {e.row.original.expireDate
                  ? moment(e.row.original.expireDate).format('DD MMM, YYYY hh:mm A')
                  : moment().format('DD MMM, YYYY hh:mm A')}
              </span>
          </>
        );
      },
    },
    {
      Header: 'Action',
      Cell: e => {
        if (e.row.original._id) {
          return (
            <>
              <div className='d-flex'>
                <Button
                  type='button'
                  disabled={!permissioncontext.edit}
                  className='Et-btn edit-btn1'
                  onClick={() => {
                    getEditaData(e.row.original);
                  }}
                >
                  Edit
                </Button>
                {/* <Button
                  type='button'
                  disabled={!permissioncontext.delete}
                  className='ms-2 Dt-btn'
                  onClick={() => {
                    handleShowDt(e.row.original);
                  }}
                >
                  Delete
                </Button> */}
              </div>
            </>
          );
        }
      },
    },
    {
      Header: "View",
      Cell: (e) => {
        return (
          <>
            <span>
                  <Link to={`/couponDetails?couponId=${e.row.original?._id}`}
                        state={{ permissioncontext: permissioncontext }}
                        >
                     <Eye style={{ fontSize: '1.5rem', color: 'green' }} />
                  </Link>
            </span>
          </>
        );
      },
    },
  ]);

  useEffect(() => {
    handleCountData()
    if (flag === true) {
    fetchReportList(skip, limit, pageIndex);
    }
  }, [flag]);


  const fetchReportList = useCallback(
    async (skip, limit, index) => {
      try {
        const response = await APIServices.get(
          `couponMaster/getAll/${skip}/${limit}?status=${status}&title=${title}&couponCode=${couponCode}&couponType=${couponType}&globalCouponType=${globalCouponType}&startDate=${
            startDate ? formateDate(startDate) : ''
          }&endDate=${endDate ? formateDate(endDate) : ''}&expireDate=${expireDate?.expireDate}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount);
          setTableData(response.data?.Listdata);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, title,couponCode, status, couponType , globalCouponType,startDate, endDate,expireDate?.expireDate]
  );

  const handleFilterClick = () => {
    if (title || couponCode || status || couponType || globalCouponType || startDate || endDate || expireDate?.expireDate ) {
      fetchReportList(skip, limit, 0, title, couponCode ,status,couponType , globalCouponType, startDate, endDate, expireDate?.expireDate);
    }
  };

  return (
    <div>
      {permissioncontext.view ? (
        <div className='App'>
          <div className='gita_quote_heading'>
            <div className='container'>
              <div className='d-flex align-items-center justify-content-between'>
                <h4 className='hd-list-f left-q '>
                  {' '}
                  Coupon List <small>({allDataCount})</small>
                </h4>
                <h4 className="hd-list-f font_20 left-q counter-top">
                Total Coupons <small>{countData?.totalCouponCount}</small>
                    </h4>
                <h4 className="hd-list-f font_20 left-q counter-top">
                Total Coupon Discount <small>{countData?.totalCouponDiscount}</small>
                    </h4>
                  

                <div className='_btn'>
                  <Button
                    style={{ backgroundColor: 'green' }}
                    className={!permissioncontext.add?'by-btn as_btn me-0 disabled-btn':'by-btn as_btn me-0'}
                    variant='primary'
                    disabled={!permissioncontext.add}
                    onClick={() => navigate('/couponMasterAddEdit')}
                  >
                    Add Coupon
                </Button>
              </div>
              </div>
            </div>
          </div>

          <Container className='' fluid>
            <div className='feed_area box-shadow my-3 p-20 br-6'>
              <div className='chat_search_box py-0'>
                <div className='chat-search-field'>
                  <Row>
                    <Form>
                      <Form.Group as={Row} className='align-items-end' controlId=''>
                        <Col lg={3} md={6} className='mb-3'>
                          <Form.Label className=''>Title</Form.Label>
                          <Form.Control
                            className='form-ch'
                            name='title'
                            type='search'
                            placeholder='Search By Title'
                            value={title}
                            onChange={e => {
                              setTitle(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col lg={3} md={6} className='mb-3'>
                          <Form.Label className=''>Coupon Code</Form.Label>
                          <Form.Control
                            className='form-ch'
                            name='couponCode'
                            type='search'
                            placeholder='Search By Coupon Code'
                            value={couponCode}
                            onChange={e => {
                              setCouponCode(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>
                        
                        <Col lg={3} md={6} className='mb-3'>
                          <Form.Label className=''>Coupon Type (INR)</Form.Label>
                          <Form.Select
                            aria-label='categories'
                            className='astroWatch-select-hover mt-0 hd-cr form-ch'
                            value={couponType}
                            onChange={e => {
                              setCouponType(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option>Select Status</option>
                            <option value='percent'>Percent</option>
                            <option value='flat'>Flat</option>
                          </Form.Select>
                        </Col>

                        <Col lg={3} md={6} className='mb-3'>
                          <Form.Label className=''>Global Coupon Type</Form.Label>
                          <Form.Select
                            aria-label='categories'
                            className='astroWatch-select-hover mt-0 hd-cr form-ch'
                            value={globalCouponType}
                            onChange={e => {
                              setGlobalCouponType(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option>Select Status</option>
                            <option value='percent'>Percent</option>
                            <option value='flat'>Flat</option>
                          </Form.Select>
                        </Col>
                        
                        <Col lg={3} md={6} className='mb-3'>
                          <Form.Label className=''>Status</Form.Label>
                          <Form.Select
                            aria-label='categories'
                            className='astroWatch-select-hover mt-0 hd-cr form-ch'
                            value={status}
                            onChange={e => {
                              setStatus(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option>Select Status</option>
                            <option value='true'>Active</option>
                            <option value='false'>Inactive</option>
                          </Form.Select>
                        </Col>

                        <Col lg={3} md={6} className='mb-3'>
                          <Form.Label>Search By Created Date</Form.Label>
                          <div className='date-range-wrapper'>
                            <div className='select-icon-wrapper'>
                              <select
                                className='select-range'
                                value={selectedRange}
                                onChange={e => handleDateRange(e.target.value)}
                              >
                                <option value='disabled'>Select Date Range</option>
                                <option value='today'>Today</option>
                                <option value='yesterday'>Yesterday</option>
                                <option value='thisWeek'>This Week</option>
                                <option value='last7Days'>Last 7 Days</option>
                                <option value='thisMonth'>This Month</option>
                                <option value='lastMonth'>Last Month</option>
                                <option value='customDate'>Custom Date</option>
                              </select>
                              <img src='/images/down-arrow.png' className='select-icons' />
                            </div>

                            {selectedRange === 'customDate' && (
                              <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={new Date()}
                                onChange={update => {
                                  const [start, end] = update;
                                  setStartDate(start);
                                  setEndDate(end);
                                }}
                                // isClearable={true}
                                placeholderText='Select custom date range'
                                dateFormat='yyyy-MM-dd'
                              />
                            )}
                          </div>
                        </Col>

                        <Col lg={3} md={6} className='mb-3'>
                          <Form.Label>Expire Date</Form.Label>

                          <DatePicker
                            placeholderText={'Select a Expire Date'}
                            className='form-control form-ch fil-date-pick w-100'
                            name='expireDate'
                            selected={expireDate.expireDate ? new Date(expireDate.expireDate) : null}
                            onChange={date => {
                              handleInputChange(date);
                              setSkip(0);
                            }}
                            dateFormat='yyyy-MM-dd'
                          />
                       </Col>

                        <Col lg={3} md={6} className='mb-3'>
                          <Button
                            variant='secondary'
                            className='as_btn ms-1'
                            onClick={() => {
                              handleFilterClick();
                            }}
                          >
                            Filter
                          </Button>
                          <Button
                            variant='secondary'
                            className='as_btn reset-btn'
                            onClick={() => {
                              handleReset();
                            }}
                          >
                            Reset
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </Row>
                </div>
              </div>
            </div>
            {tableData && tableData.length > 0 ? (
              <DynamicDataTableWithCount
                columns={columns}
                data={tableData}
                totalCount={pageCount}
                initialState={{
                  pageIndex: pageIndex,
                  pageSize: limit,
                }}
                fetchData={fetchReportList}
                forcePage={skip / limit}
              />
            ) : (
              <h2 className='no_records'>Records not Available</h2>
            )}
          </Container>

          <Modal
            centered
            show={showdt}
            size='sm'
            aria-labelledby='example-modal-sizes-title-sm'
            backdrop='static'
            onHide={handleCloseDt}
          >
            <Modal.Header className='modal-header-hd'>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
                Cancel
              </Button>
              <Button variant='primary' className='success_btn' onClick={handleDelete}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          {/* <ToastContainer /> */}
        </div>
     ) : (
        <Notauthorized />
      )} 
    </div>
  );
};

export default CouponMaster;
