import { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, Form, Col, Row, Container } from 'react-bootstrap';
import APIServicenew from '../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies, formateDate } from '../utils/Helper';
import { UserContext } from '../context/UserContextProvider';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Papa from 'papaparse';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';
import Select from 'react-select';

function Dashboard() {
  document.title = 'Dash Board';
  const permissioncontext = usePermissionData('dashboard');
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  let astroUserId = cookies.get('astroUserId');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(50);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [countData, setCountData] = useState()
  const [title, setTitle] = useState('');
  const [userName, setUserName] = useState('');
  const [userMobNum, setUserMobNum] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [source, setSource] = useState('');
  const [status, setStatus] = useState('');
  const [bookingStatus, setBookingStatus] = useState('');
  const [date, setDate] = useState('');
  const [language, setLanguage] = useState('');
  const [flag, setFlag] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedRange, setSelectedRange] = useState('today');
  const [countryList, setcountryList] = useState([]);
  const [country, setCountry] = useState('');
  const [inputStateP, setInputStateP] = useState({
    pujaDate: '',
  });
  const [transactionId, setTransactionId] = useState('');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  // filters
  const [type, setType] = useState('');

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  const handleDateRange = range => {
    const now = new Date();
    let start, end;

    if (range === 'disabled') {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange('');
      return;
    }

    switch (range) {
      case 'today':
        start = end = now;
        break;
      case 'yesterday':
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case 'thisWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case 'last7Days':
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'lastMonth':
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'customDate':
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const handleInputChangeP = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setInputStateP(prevState => ({ ...prevState, pujaDate: formattedDate }));
  };

  const statusMap = {
    completed: { text: 'Completed', testClass: 'text-success fw-bold' },
    accepted: { text: 'Accepted', testClass: 'text-success fw-bold' },
    'in-Progress': { text: 'In-Progress', testClass: 'text-warning fw-bold' },
    requested: { text: 'Requested', testClass: 'text-pending fw-bold' },
    Failed: { text: 'Failed', testClass: 'text-danger fw-bold' },
    failed: { text: 'Failed', testClass: 'text-danger fw-bold' },
    rejected: { text: 'Rejected', testClass: 'text-danger fw-bold' },
    cancelled: { text: 'Cancelled', testClass: 'text-danger fw-bold' },
    paid: { text: 'Paid', testClass: 'text-success fw-bold' },
    Paid: { text: 'Paid', testClass: 'text-success fw-bold' },
    pending: { text: 'Pending', testClass: 'text-warning fw-bold' },
    Pending: { text: 'Pending', testClass: 'text-warning fw-bold' },
    processing: { text: 'Processing', testClass: 'text-warning fw-bold' },
    fail: { text: 'Fail', testClass: 'text-danger fw-bold' },
    Fail: { text: 'Fail', testClass: 'text-danger fw-bold' },
    unPaid: { text: 'unPaid', testClass: 'text-danger fw-bold' },
  };
  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Date',
      Cell: e => {
        return <span>{moment.utc(e.row.original.createdAt).local().format('DD MMM, YYYY hh:mm A')}</span>;
      },
    },
    {
      Header: 'Record',
      Cell: e => {
        return (
          <span>
            {e.row.original?.type === 'call'
              ? 'Call Number :-' + e.row.original.title
              : e.row.original?.type === 'chat'
              ? 'Chat Number :-' + e.row.original.title
              : e.row.original.title || '_'}
          </span>
        );
      },
    },
    {
      Header: 'Name',
      Cell: e => {
        return <span>{e.row.original?.name ?? '_'}</span>;
      },
    },
    {
      Header: 'Contact Number',
      Cell: e => {
        return <span>{e.row.original.contactNumber ?? '_'}</span>;
      },
    },
    {
      Header: 'Type',
      Cell: e => {
        return <span>{e.row.original?.type ?? '_'}</span>;
      },
    },
    {
      Header: 'Amount',
      Cell: e => {
        const symbol = e.row.original.currencySymbol ? e.row.original.currencySymbol : '';
        return (
          <span>
            {e.row.original?.type == 'manokamna' && e.row.original?.price == '0'
              ? 'Free'
              : e.row.original?.price
              ? symbol + e.row.original?.price
              : '_'}
          </span>
        );
      },
    },
    {
      Header: 'Status',
      Cell: e => {
        return (
          <span className={statusMap[e.row.original.status]?.testClass}>{statusMap[e.row.original.status]?.text}</span>
          //  {e.row.original?.refund_status === "pending" ?   <span className="text-pending fw-bold font_14">Refunded</span> : "" }
          //  </>
        );
      },
    },
    {
      Header: 'Country',
      Cell: e => {
        return <span>{e.row.original?.country ?? '_'}</span>;
      },
    },
    {
      Header: 'language',
      Cell: e => {
        return <span>{e.row.original?.language ?? '_'}</span>;
      },
    },
    {
      Header: 'Source',
      Cell: e => {
        return <span>{e.row.original?.source ?? '_'}</span>;
      },
    },
    {
      Header: 'Puja Date',
      Cell: e => {
        return (
          <span>{e.row.original.pujaDate ? moment(e.row.original.pujaDate).format('DD MMM, YYYY hh:mm A') : '_'}</span>
        );
      },
    },
    {
      Header: 'Booking Status(refund)',
      Cell: e => {
        return (
          <>
            {e.row.original?.type == 'manokamna' || e.row.original?.type == 'chadhawa' ?(
              <>
                {e.row.original?.refund_status === 'pending' &&
                e.row.original?.bookingStatus === 'cancelled' ? (
                  <div className='d-inline-flex flex-column gy-2'>
                    <span className='text-danger fw-bold font_14 mb-1'>
                      {e.row.original?.bookingStatus ?? '_'}
                    </span>
                    <span className='text-pending fw-bold font_14'>pending</span>
                  </div>
                ) : e.row.original?.refund_status === 'completed' &&
                  e.row.original?.bookingStatus === 'cancelled' ? (
                  <div className='d-inline-flex flex-column gy-2'>
                    <span className='text-danger fw-bold font_14 mb-1'>
                      {e.row.original?.bookingStatus ?? '_'}
                    </span>
                    <span className='text-success fw-bold font_14'>Refunded</span>
                  </div>
                ) : (
                  <span className={statusMap[e.row.original.bookingStatus]?.testClass}>
                    {e.row.original?.bookingStatus ?? '_'}
                  </span>
                )}
              </>
            ) : (
              <span className={statusMap[e.row.original.bookingStatus]?.testClass}>
                    {e.row.original?.bookingStatus ?? '_'}
              </span>
          
            )}
          </>
        );
      },
    },
    {
      Header: 'Other Details',
      Cell: e => {
        return (
          <div>
            {e.row.original?.type === 'puja booking' ? (
              <>
                <span> Gotra : {e.row.original.gotra ? e.row.original.gotra : ''}</span>
                <br />
                <span> Plan Name : {e.row.original.pujaPlan ? e.row.original.pujaPlan?.title : ''}</span>
                <br />
                <span>
                  {' '}
                  Prasad Price :{' '}
                  {e.row.original.otherDetails?.prasad_price
                    ? e.row.original?.currencySymbol + e.row.original.otherDetails?.prasad_price
                    : ''}
                </span>
                <br />
                <span>
                  Dakshina Price :{' '}
                  {e.row.original.otherDetails.dakshina_price
                    ? e.row.original?.currencySymbol + e.row.original.otherDetails.dakshina_price
                    : ''}
                </span>
                <br />
                <span>
                  Chadawa :
                  {e.row.original?.otherDetails?.chadawa_items &&
                  e.row.original?.otherDetails?.chadawa_items?.length > 0
                    ? e.row.original?.currencySymbol +
                      e.row.original?.otherDetails?.chadawa_items.reduce((acc, item) => acc + parseFloat(item.price), 0)
                    : 'No'}
                </span>
                <br />
                <span>
                  Total Price :{' '}
                  {e.row.original.otherDetails.total_price
                    ? e.row.original?.currencySymbol + e.row.original.otherDetails.total_price
                    : ''}
                </span>
              </>
            ) : e.row.original?.type === 'career report' ? (
              <>
                <span> Email : {e.row.original.otherDetails?.email}</span>
                <br />
                <span>Address: {e.row.original.otherDetails?.additionalDetails?.address}</span>
                <br />
                <span>
                  DOB:{' '}
                  {moment
                    .utc(e.row.original.otherDetails?.additionalDetails?.dob)
                    .set({
                      hour: e.row.original.otherDetails?.additionalDetails?.hour,
                      minute: e.row.original.otherDetails?.additionalDetails?.min,
                    })
                    .format('DD MMM, YYYY hh:mm A')}
                </span>

                <br />

                <span>Gender: {e.row.original.otherDetails?.additionalDetails?.gender}</span>
                <br />
                <span> Transaction Id : {e.row.original?.TID ?? '_'}</span>
              </>
            ) : e.row.original?.type === 'chat' ? (
              <>
                <span> birthPlace : {e.row.original.otherDetails?.birthPlace}</span>
                <br />
                <span> birthTime :{moment(e.row.original.otherDetails?.birthTime).format('DD MMM, **** h:mm a')}</span>
                <br />
                <span>Name: {e.row.original?.name}</span>
                <br />
                <span>Gender: {e.row.original.otherDetails?.gender}</span>
                <br />
                <span>Astrologer Name: {e.row.original.otherDetails?.astrologer}</span>
                <br />
                <span>Astrologer Number: {e.row.original.otherDetails?.astrologerNumber}</span>
                <br />
                <span>IsFree: {e.row.original.otherDetails?.isFree === true ? 'Yes' : 'No'}</span>
                <br />
                <span>Duration: {e.row.original.otherDetails?.duration + 'min'}</span>
                <br />
                <span>Device: {e.row.original.otherDetails?.deviceType}</span>
                <br />
                <span>
                  Price Per Minute:{' '}
                  {e.row.original?.currencySymbol + e.row.original.otherDetails?.astrologerChatPricePerMinute}
                </span>
              </>
            ) : e.row.original?.type === 'call' ? (
              <>
                <span> birthPlace : {e.row.original.otherDetails?.birthPlace}</span>
                <br />
                <span> birthTime :{moment(e.row.original.otherDetails?.birthTime).format('DD MMM, **** h:mm a')}</span>
                <br />
                <span>Name: {e.row.original?.name}</span>
                <br />
                <span>Gender: {e.row.original.otherDetails?.gender}</span>
                <br />
                <span>Astrologer Name: {e.row.original.otherDetails?.astrologer}</span>
                <br />
                <span>Astrologer Number: {e.row.original.otherDetails?.astrologerNumber}</span>
                <br />
                <span>IsFree: {e.row.original.otherDetails?.isFree === true ? 'Yes' : 'No'}</span>
                <br />
                <span>Duration: {e.row.original.otherDetails?.duration + 'min'}</span>
                <br />
                <span>Device: {e.row.original.otherDetails?.deviceType}</span>
                <br />
                <span>
                  Price Per Minute:{' '}
                  {e.row.original?.currencySymbol + e.row.original.otherDetails?.astrologercallPricePerMinute}
                </span>
              </>
            ) : e.row.original?.type === 'chadhawa' ? (
              <>
                <span> Chadawa Id : {e.row.original?.otherDetails?.id}</span>
                <br />
                <span> Chadawa Name : {e.row.original?.otherDetails?.chadawa_name ?? '_'}</span>
                <br />
                <span> Transaction Id : {e.row.original?.TID ?? '_'}</span>
              </>
            ) : e.row.original?.type === 'manokamna' ? (
              <>
                <span> Manokamna Id : {e.row.original?.otherDetails?.id}</span>
                <br />
                <span> Manokamna Name : {e.row.original.otherDetails?.chadawa_name ?? '_'}</span>
                <br />
                <span> Transaction Id : {e.row.original?.TID ?? '_'}</span>
              </>
            ) : (
              '_'
            )}
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    fetchCountryList();
    handleCountData();
  }, []);

  const fetchCountryList = async () => {
    let response = await APIServicesSec.get(`country/getPhoneCode`, headers);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setcountryList(apiData?.data);
      }
    }
  };

  const handleSelectChange = selected => {
    setCountry(selected);
    setSkip(0);
  };

  const options = countryList
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(item => ({
      value: item?.name,
      label: item?.name,
    }));

    const handleCountData = async (id) => {
      const res = await APIServices.get(`dashBoard/getDashBoardCount`, headers);
  
      if (res && res.status === 200) {
        if (res.data && res.data?.status) {
          setCountData(res.data.resData);
        } else {
          console.log("error");
        }
      }
    };
  const fetchProfilesByPagination = useCallback(
    async (skip, limit, index) => {
      let countryV = country?.value === undefined ? '' : country?.value;
      try {
        const response = await APIServices.get(
          `dashBoard/dashBoardHistory/${skip}/${limit}?type=${type}&country=${countryV}&name=${userName}&contactNumber=${userMobNum}&title=${title}&source=${source}&status=${status}&bookingStatus=${bookingStatus}&language=${language}&currencyCode=${currencyCode}&startDate=${
            startDate ? formateDate(startDate) : ''
          }&endDate=${endDate ? formateDate(endDate) : ''}&pujaDate=${
            inputStateP?.pujaDate
          }&transactionId=${transactionId}`,
          headers
        );
        let data = response.response?.data;
        if (response?.status === 200) {
          setPageCount(Math.ceil(response.data?.total / limit));
          setAllDataCount(response.data?.total);
          setTableData(response?.data?.data);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [
      pageIndex,
      skip,
      type,
      userName,
      userMobNum,
      title,
      source,
      status,
      bookingStatus,
      language,
      country,
      currencyCode,
      startDate,
      endDate,
      inputStateP?.pujaDate,
      transactionId,
    ]
  );

  const handleFilterClick = () => {
    if (
      type ||
      skip ||
      userName ||
      userMobNum ||
      title ||
      status ||
      bookingStatus ||
      source ||
      language ||
      currencyCode ||
      country ||
      startDate ||
      endDate ||
      inputStateP?.pujaDate ||
      transactionId
    ) {
      fetchProfilesByPagination(
        skip,
        limit,
        0,
        userName,
        userMobNum,
        title,
        source,
        status,
        bookingStatus,
        language,
        type,
        country,
        currencyCode,
        startDate,
        endDate,
        inputStateP?.pujaDate,
        transactionId
      );
    }
  };

  const resetData = () => {
    setDate('');
    setFlag(true);
    setCountry('');
    setPageIndex(0);
    setType('');
    setUserMobNum('');
    setUserName('');
    setTitle('');
    setStatus('');
    setBookingStatus('');
    setSource('');
    setLanguage('');
    setCurrencyCode('');
    setStartDate(new Date());
    setEndDate(new Date());
    setSelectedRange('today');
    setInputStateP({
      pujaDate: '',
    });
    setTransactionId('');
  };

  useEffect(() => {
    fetchProfilesByPagination(skip, limit, pageIndex);
  }, [flag]);

  return (
    <>
      {permissioncontext.view ? (
      <div className='App'>
        <div className='gita_quote_heading'>
          {/* <div className='container'>
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q '>
                {' '}
                Dashboard <small>({allDataCount})</small>
              </h4>
            </div>
          </div> */}
          <div className="container">
          <div className="overflow-x-auto">
            <div className="counter-wrapper d-flex align-items-center justify-content-between">
              <h4 className="hd-list-f font_20 left-q counter-top">
               Dashboard <small>{allDataCount}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Puja Booking <small>{countData?.userPujaBookingAddedPaidToday}/{countData?.userPujaBookingAddedToday}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Report <small>{countData?.userReportsAddedPaidToday}/{countData?.userReportsAddedToday}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Chat <small>{countData?.userChatAddedPaidToday}/{countData?.userChatAddedToday}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Call <small>{countData?.userCallAddedPaidToday}/{countData?.userCallAddedToday} </small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Chadhawa <small>{countData?.userChadhawaAddedPaidToday}/{countData?.userChadhawaAddedToday} </small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Manokamna <small>{countData?.userManokamnaAddedPaidToday}/{countData?.userManokamnaAddedToday} </small>
              </h4>
            </div>
          </div>
        </div>
        </div>

        <Container className='' fluid>
          <div className='feed_area box-shadow my-3 p-20 br-6'>
            <div className='chat_search_box py-0'>
              <div className='chat-search-field'>
                <Form>
                  <Form.Group as={Row} className='align-items-end' controlId=''>
                    <Col lg={3} md={6} className='mb-3 mb-lg-3'>
                      <Form.Label className='label-f-w-cd'>Select By Date</Form.Label>
                      <div className='date-range-wrapper mt-1'>
                        <div className='select-icon-wrapper'>
                          <select
                            className='select-range'
                            value={selectedRange}
                            onChange={e => handleDateRange(e.target.value)}
                          >
                            {/* <option value=''>Select Date Range</option> */}
                            <option value='today'>Today</option>
                            <option value='yesterday'>Yesterday</option>
                            <option value='thisWeek'>This Week</option>
                            <option value='last7Days'>Last 7 Days</option>
                            <option value='thisMonth'>This Month</option>
                            <option value='lastMonth'>Last Month</option>
                            <option value='customDate'>Custom Date</option>
                          </select>
                          <img src='/images/down-arrow.png' className='select-icons' />
                        </div>

                        {selectedRange === 'customDate' && (
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={update => {
                              const [start, end] = update;
                              setStartDate(start);
                              setEndDate(end);
                            }}
                            isClearable={true}
                            placeholderText='Select custom date range'
                            dateFormat='yyyy-MM-dd'
                          />
                        )}
                      </div>
                    </Col>
                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className='label-f-w-cd'>Record</Form.Label>

                      <Form.Control
                        className=' mt-1 form-ch'
                        name='title'
                        type='text'
                        placeholder='Search By record'
                        value={title}
                        onChange={e => {
                          setTitle(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className='label-f-w-cd'>User Name</Form.Label>

                      <Form.Control
                        className=' mt-1 form-ch'
                        name='name'
                        type='text'
                        placeholder='Search By Name'
                        value={userName}
                        onChange={e => {
                          setUserName(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className='label-f-w-cd'>User Mobile Number</Form.Label>

                      <Form.Control
                        className=' mt-1 form-ch'
                        name='number'
                        type='number'
                        placeholder='Search Mobile Number'
                        value={userMobNum}
                        onChange={e => {
                          setUserMobNum(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>

                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label class='label-f-w-cd'>Country</Form.Label>
                      <Select
                        className=' mt-2'
                        classNamePrefix='react-select'
                        options={options}
                        value={country}
                        onChange={handleSelectChange}
                        placeholder='Search by Country...'
                        isClearable
                      />
                    </Col>

                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className='label-f-w'>Type</Form.Label>
                      <Form.Select
                        aria-label='categories'
                        className='  astroWatch-select-hover mt-0 hd-cr form-ch'
                        value={type}
                        onChange={e => {
                          setType(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value=''>Select</option>
                        <option value='puja booking'>Puja Booking</option>
                        <option value='career report'>Career Report</option>
                        <option value='chat'>Chat</option>
                        <option value='call'>Call</option>
                        <option value='chadhawa'>Chadhawa</option>
                        <option value='manokamna'>Manokamna</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className='mb-3 '>
                      <Form.Label className='label-f-w-cd'>Currency Code</Form.Label>
                      <Form.Select
                        aria-label='label-selected-menu'
                        className='label-selected-menu text-capitalize  w-50 hd-cr mt-1 form-ch'
                        value={currencyCode}
                        onChange={e => {
                          setCurrencyCode(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value=''> Select</option>
                        <option value='INR'>INR</option>
                        <option value='USD'>USD</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label>Puja Date</Form.Label>
                      <DatePicker
                        placeholderText={'Select a puja date'}
                        className='form-control form-ch fil-date-pick w-100'
                        name='pujaDate'
                        selected={inputStateP.pujaDate ? new Date(inputStateP.pujaDate) : null}
                        onChange={date => {
                          handleInputChangeP(date);
                          setSkip(0);
                        }}
                        dateFormat='yyyy-MM-dd'
                      />
                    </Col>
                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className='label-f-w-cd'>Transaction Id</Form.Label>

                      <Form.Control
                        className=' mt-1 form-ch'
                        name='transactionId'
                        type='text'
                        placeholder='Search By Transaction Id'
                        value={transactionId}
                        onChange={e => {
                          setTransactionId(e.target.value);
                          setSkip(0);
                        }}
                      />
                    </Col>
                    <Col lg={3} md={6} className='mb-3 mb-lg-3'>
                      <Form.Label className='label-f-w-cd'>Source</Form.Label>
                      <Form.Select
                        aria-label='label-selected-menu'
                        className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-1 form-ch'
                        value={source}
                        onChange={e => {
                          setSource(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value=''> All</option>
                        <option value='app'>App</option>
                        <option value='web'>Web</option>
                        <option value='global'>Global</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className='mb-3 mb-lg-3'>
                      <Form.Label className='label-f-w-cd'> Payment Status</Form.Label>
                      <Form.Select
                        aria-label='label-selected-menu'
                        className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-1 form-ch'
                        value={status}
                        onChange={e => {
                          setStatus(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value=''>Select</option>
                        <option value='pending'>Pending</option>
                        <option value='paid'>Paid</option>
                        <option value='failed'>Failed</option>
                        <option value='fail'>Fail</option>
                        <option value='unPaid'>UnPaid</option>
                      </Form.Select>
                    </Col>

                    <Col lg={3} md={6} className='mb-3 mb-lg-3 '>
                      <Form.Label className='label-f-w-cd'>Select Booking Status</Form.Label>
                      <Form.Select
                        aria-label='label-selected-menu'
                        className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                        value={bookingStatus}
                        onChange={e => {
                          setBookingStatus(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value=''> Select </option>
                        <option value='processing'>Processing</option>
                        <option value='completed'>Completed</option>
                        <option value='cancelled'>Cancelled</option>
                        <option value='accepted'>Accepted</option>
                        <option value='in-Progress'>In-Progress</option>
                        <option value='requested'>Requested</option>
                        <option value='rejected'>Rejected</option>
                      </Form.Select>
                    </Col>
                    <Col lg={3} md={6} className='mb-3'>
                      <Form.Label className='label-f-w'>Language</Form.Label>
                      <Form.Select
                        aria-label='languages'
                        className='  astroWatch-select-hover mt-0 hd-cr form-ch'
                        value={language}
                        onChange={e => {
                          setLanguage(e.target.value);
                          setSkip(0);
                        }}
                      >
                        <option value=''>Select</option>
                        <option value='english'>English</option>
                        <option value='hindi'>Hindi</option>
                      </Form.Select>
                    </Col>
                    <Col md={6} className='mb-3'>
                      <Button
                        variant='secondary'
                        className='as_btn ms-1'
                        onClick={() => {
                          handleFilterClick();
                          setSkip(0);
                        }}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='secondary'
                        className='as_btn ms-2 reset-btn'
                        onClick={() => {
                          resetData();
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </Container>
        {/* </div> */}
        <div className='overflow-scroll'>
          {tableData && tableData.length > 0 ? (
            <DynamicDataTableWithCount
              customCSS='customCSS'
              columns={columns}
              data={tableData}
              totalCount={pageCount}
              initialState={{
                pageIndex: pageIndex,
                pageSize: limit,
              }}
              fetchData={fetchProfilesByPagination}
              forcePage={skip / limit}
            />
          ) : (
            <h2 className='no_records'>Records not Available</h2>
          )}
        </div>
      </div>
      ) : (
        <Notauthorized />
      )} 
    </>
  );
}

export default Dashboard;
