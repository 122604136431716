import { useEffect, useState, useContext } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContextProvider';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash, FaArrowLeft } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
import { setCookies } from '../../utils/Helper';
import Notauthorized from '../../components/Notauthorized';


const PujaFAQ = () => {
  document.title = 'Puja FAQ';
  const location = useLocation()
  const  permissioncontext  = location.state?.keyFilter || {};
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [questionHi ,setQuestionHi] = useState('');
  const [answerHi, setAnswerHi] = useState('');
  const [sortOrder ,setSortOrder] = useState();
  const [loading, setLoading] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataCount, setAllDataCount] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  useEffect(() => {
    fetchData();
  }, [offset]);

  async function fetchData() {
    setLoading(true);
    let response = await APIServices.get(`pujaFaq/getAll/${offset}/${perPage}`, headers);
    let data = response?.response?.data;
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata);
        setAllDataCount(apiData.totalCount);
        setLoading(false);
      }
    } else {
      if (data.status == 4) {
        logout();
      }
    }
  }
  const handleShow = async item => {
    setShowModal(true);
    setQuestion(item?.question);
    setSelectedId(item?._id);
    setAnswer(item?.answer);
    setQuestionHi(item?.questionHi);
    setAnswerHi(item?.answerHi);
    setSortOrder(item?.sortOrder);
  };
  const handleClose = () => {
    setErrors('');
    setSelectedId();
    setAnswer('');
    setQuestion('');
    setAnswerHi('');
    setQuestionHi('');
    setSortOrder()
    // setFileCaartiimage("")
    setShowModal(false);
  };

  const handlePageClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setOffset(newOffset);
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!question || question === '') newErrors.question = 'Please enter question in english.';

    if (!answer && answer === '') newErrors.answer = 'Please enter answer in english.';

    if (!questionHi || questionHi === '') newErrors.questionHi = 'Please enter question in hindi.';

    if (!answerHi && (answerHi === ''||answerHi === null)) newErrors.answerHi = 'Please enter answer in hindi.';

    return newErrors;
  };

  async function handleCreate() {
    let payload = new FormData();
    payload.append('question', question);

    payload.append('answer', answer);

    payload.append('questionHi', questionHi);

    payload.append('answerHi', answerHi);

    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let response = await APIServices.post(`pujaFaq/add`, payload, headers);
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowModal(false);
          toast.success(apiData?.message);
          setLoader(false);
          setQuestion('');
          setAnswer('');
          setQuestionHi('')
          setAnswerHi('')
          fetchData();
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false);
        }
      }
      setLoader(false);
    }
  }

  async function handleEdit() {
    const formData = new FormData();

    formData.append('question', question);
    formData.append('answer', answer);

    formData.append('questionHi', questionHi);

    formData.append('answerHi', answerHi);

    formData.append('sortOrder', sortOrder);
    
    const headers = { Authorization: `Bearer ${token}` };
    const newErrors = validateInputs();
    setLoader(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false);
    } else {
      let response = await APIServices.put(`pujaFaq/editOne/${selectedId}`, formData, headers);
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          setShowModal(false);
          setSelectedId()
          setQuestion('');
          setAnswer('');
          setQuestion('')
          setAnswerHi('')
          setSortOrder()
          fetchData();
          setLoader(false);
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false);
        }
      }
      setLoader(false);
    }
  }

  const handleCloseDt = () => setShowDt(false);
  const handleShowDt = id => {
    setShowDt(true);
    setSelectedId(id);
  };
  const handledelete = async () => {
    let res = await APIServices.delete(`pujaFaq/findByIdAndremove/${selectedId}`, headers);
    if (res?.status === 200) {
      setShowDt(false);
      setSelectedId()
      setOffset(0);
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData();
      }
    }
  };

  function handleChange(e, field) {
    let key, value;
    key = e?.target?.name;
    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
  }

  return (
    <>
    {permissioncontext?.view ? 
    <>
      <div className='main aarti_section'>
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q'>
              <FaArrowLeft className='hd-cr ' style={{ marginRight: '15px' }} size={25} onClick={() => navigate(-1)} />
              Puja FAQ <small>({allDataCount})</small>
            </h4>

            <div className='_btn'>
              <Button
                className='by-btn as_btn me-0'
                variant='primary'
                disabled={!permissioncontext.add}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Add FAQ
              </Button>
              {/* &nbsp;&nbsp; */}
            </div>
          </div>
        </div>
        <div className='aarti_inner pt-4'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 col-lg-12'>
                {loading ? (
                  <div className='center'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <>
                    <div className='row'>
                      {allData?.map(item => (
                        <div className='col-md-6 col-lg-4 col-xl-3'>
                          <div className='card mb-3 ' style={{height: '15rem'}} key={item?._id}>
                            <div className='card-header'></div>

                            <div class='card-body'>
                              <h5 class='card-title font_18'>{item?.question}</h5>

                              <p class='card-text font_14'>
                              {item?.answer}
                              </p>
                            </div>
                            <p style={{ textAlign: 'center' }} className='description font-500 mb-2'>
                              <span className='calicon'>
                                <i className='ri-calendar-line'></i>
                              </span>
                              {new Date(item.createdAt).toDateString()}{'  '}{'  '}
                            <FaEdit
                              className={permissioncontext.edit?'me-2  ms-4 hd-cr':'me-2  ms-4 hd-cr disabled-btn'}
                              // className='me-2  ms-4 hd-cr'
                              size={20}
                              onClick={e => {
                                if(permissioncontext.edit){
                                  handleShow(item);
                                }
                              }}
                             
                            />
                            <FaTrash
                              className={permissioncontext.delete?'dt-btn hd-cr ':'dt-btn hd-cr disabled-btn'}
                              // className='dt-btn hd-cr '
                              size={20}
                              onClick={e => {
                                if(permissioncontext.delete){
                                  handleShowDt(item?._id);
                                }
                              }}
                            />
                            <span class="badge badge-info ms-1">{item?.sortOrder}</span>
                            </p>
                           
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                <div className='pagination'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next>'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(allDataCount / perPage)}
                    previousLabel='< Previous'
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={offset / perPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
      centered
      scrollable
        show={showModal}
        backdrop='static'
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title> {`${selectedId ? 'Edit' : 'Add'} FAQ`} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
              Question
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Question...'
                  name='question'
                  defaultValue={question}
                  isInvalid={!!errors?.question}
                  className='form-control '
                  onChange={e => {
                    setQuestion(e.target.value);
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.question}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
               Answer
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  as='textarea'
                  style={{ height: 150 }}
                  onChange={e => {
                    setAnswer(e.target.value);
                    handleChange(e);
                  }}
                  defaultValue={answer}
                  isInvalid={!!errors?.answer}
                  name='answer'
                  className='form-control'
                  placeholder='Answer...'
                />
                <Form.Control.Feedback type='invalid'>{errors?.answer}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
              Question (Hindi)
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Question...'
                  name='questionHi'
                  defaultValue={questionHi}
                  isInvalid={!!errors?.questionHi}
                  className='form-control '
                  onChange={e => {
                    setQuestionHi(e.target.value);
                    handleChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.questionHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
               Answer (Hindi)
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  as='textarea'
                  style={{ height: 150 }}
                  onChange={e => {
                    setAnswerHi(e.target.value);
                    handleChange(e);
                  }}
                  defaultValue={answerHi}
                  isInvalid={!!errors?.answerHi}
                  name='answerHi'
                  className='form-control'
                  placeholder='Answer...'
                />
                <Form.Control.Feedback type='invalid'>{errors?.answerHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            {selectedId?<>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='' column sm='3'>
                Sort Order
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='number'
                  placeholder=''
                  name='sortOrder'
                  defaultValue={sortOrder}
                  className='form-control '
                  onChange={e => {
                    setSortOrder(e.target.value);
                  }}
                />
              </Col>
            </Form.Group>
            </>:""}
          </Form>
        </Modal.Body>
        <Modal.Footer>
  
            <Button
              variant='primary'
              className='as_btn'
              onClick={e => `${selectedId ? handleEdit() : handleCreate(e)}`}
            >
              {`${selectedId ? 'Edit' : 'Add'}`}
            </Button>
          
          <Button
            variant='secondary'
            className='as_btn reset-btn'
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
      scrollable
      centered
        show={showdt}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
     : <Notauthorized/>} 
    </>
  );
};

export default PujaFAQ;
