import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../utils/APIGeneralService';
import { formateDate, setCookies } from '../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../context/UserContextProvider';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Select, { components } from 'react-select';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import usePermissionData from '../components/Customhook/custompermission';
import Notauthorized from '../components/Notauthorized';

const PujaExpensesList = () => {
  const navigate = useNavigate();
  document.title = 'Puja Expenses';
  const permissioncontext =usePermissionData("pujaExpensesList")
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const [expensesData, setExpensesData] = useState([]);
  const [expensesModal, setExpensesModal] = useState(false);
  const [totalBookingPrice ,setTotalBookingPrice] = useState(null)

  useEffect(() => {
    titleDropdown();
    fetchPujaData();
  }, []);
  

  const getPujaBookingTotalPrice = async (id) => {
    let pujaBookingTotal = await APIServices.get(`astroPooja/pujaBookingTotalPrice/${id}`, headers);
    if (pujaBookingTotal?.status === 200) {
      if (pujaBookingTotal?.data?.totalPriceOfPujaBookings) {
        setTotalBookingPrice(pujaBookingTotal?.data?.totalPriceOfPujaBookings)
      }else{
        console.log("Somthing went wrong");
        
      }
    }
  };
  // select pandit by id --

  const [dropdownData, setDropdownData] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');


  const titleDropdown = async () => {
    const response = await APIServices.get(`pandit/getPanditList`, headers);
    if (response?.status === 200) {
      setDropdownData(response?.data?.Listdata);
    }
  };

  const handleSelectChange = selected => {
    setSelectedOption(selected);
    setSkip(0);
  };

  const options = dropdownData.map(item => ({
    value: item._id,
    label: item.name,
  }));

  //   select puja by id

  const [puja, setPuja] = useState([]);

  const fetchPujaData = async () => {
    let response = await APIServices.get(`astroPooja/pujalisting`, headers);
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setPuja(apiData.Listdata);
      }
    }
  };

  const Input = props => {
    const { autoComplete = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoComplete} />;
  };

  const [selectedPujaSec, setSelectedPujaSec] = useState({ current: '' });
  const pujaOptions = [
    { value: '', label: 'All' },
    ...puja.map(item => ({
      value: item._id,
      label: `${item.title} (${moment(item.pujaDate).format('DD MMM, YYYY hh:mm A')})`,
    })),
  ];

  const handleChangeSec = selectedOption => {
    if (selectedOption) {
      setSelectedPujaSec({ current: selectedOption.value });
      handlePujaChangeSec(selectedOption);
      //   setOffset(0);
    } else {
      console.log('No option selected');
    }
  };

  const handlePujaChangeSec = selectedOption => {
    const value = selectedOption.value;

    // selectedPuja.current = e.target.value;
    setCookies('skip', 0);
  };

  //   Date

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedRange, setSelectedRange] = useState('');

  const handleDateRange = range => {
    const now = new Date();
    let start, end;

    if (range === 'disabled') {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange('');
      return;
    }

    switch (range) {
      case 'today':
        start = end = now;
        break;
      case 'yesterday':
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case 'thisWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case 'last7Days':
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'lastMonth':
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'customDate':
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setSelectedRange('');
    setSelectedPujaSec({ current: '' });
    setSelectedOption('');
    setFlag(true);
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Puja Name',
      Cell: e => {
          return(
              <Link to={`/shared-puja?pujaId=${e.row.original?.pujaId?._id}`} state={{permissioncontext:permissioncontext}}>{e.row.original?.pujaId?.title ?? '_'}</Link>
          )
        // return <span>{e.row.original.pujaId?.title ?? '_'}</span>;
          
      },
    },
    {
      Header: 'Pandit Name',
      Cell: e => {
        return <span>{e.row.original.panditName ?? '_'}</span>;

      },
    },
    {
        Header: 'Pandit Expense',
        Cell: e => {
          return <span>₹{e.row.original.panditPrice ?? '_'}.00</span>;
        },
      },
    {
      Header: 'Total Expense',
      Cell: e => {
        return <span>₹{e.row.original.totalPrice ?? '_'}.00</span>;
      },
    },

    {
      Header: 'Created At',
      Cell: e => {
        return (
          <>
            <span>
              {e.row.original.createdAt ? moment(e.row.original.createdAt).format('DD MMM, YYYY hh:mm A') : '_'}
            </span>
          </>
        );
      },
    },
    {
      Header: 'Details',
      Cell: e => {
        return (
          <>
            <h5>
              <input
                type='button'
                className={!permissioncontext.view?"Et-btn notes-btn disabled-btn":'Et-btn notes-btn '}
                disabled={!permissioncontext.view}
                value='View Expense'
                onClick={() => {
                  setExpensesData(e.row.original);
                  setExpensesModal(true);
                  getPujaBookingTotalPrice(e.row.original.pujaId._id);
                }}
              />
            </h5>
          </>
        );
      },
    },
  ]);

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  useEffect(() => {
    pujaPanditList(skip, limit, pageIndex);
  }, [flag]);

  const pujaPanditList = useCallback(
    async (skip, limit, index) => {
      try {
        let selectedOptionId = selectedOption?.value === undefined ? '' : selectedOption?.value;
        const response = await APIServices.get(
          `pandit/getAllPujaExpenceseList/${skip}/${limit}?panditId=${selectedOptionId}&pujaId=${
            selectedPujaSec?.current
          }&startDate=${startDate ? formateDate(startDate) : ''}&endDate=${endDate ? formateDate(endDate) : ''}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount);
          setTableData(response.data?.Listdata);
          setFlag(false);
          window.scrollTo({ top: 0 });
        } else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, selectedOption?.value, selectedPujaSec?.current, startDate, endDate]
  );


  const handleDownload = (data) => {
    for (let item of data) {

      if (item?.panditName) {
        item['Pandit Name'] = item?.panditName;
      } else {
        item['Pandit Name'] = '_';
      }

      
      if (item?.pujaId) {
        item['Puja Name'] = item?.pujaId?.title;
      } else {
        item['Puja Name'] = '_';
      }
      

      if (Array.isArray(item?.expenceseItem) && item?.expenceseItem.some(expense => expense?.name)) {
        item["Expencese Name"] = item.expenceseItem
          .map((expense) => `${expense?.name} (${expense?.price})`)
          .filter((name) => name)
          .join(", ");
      } else {
        item["Expencese Name"] = "-";
      }


      if (item?.panditPrice) {
        item['Pandit Price'] = item?.panditPrice;
      } else {
        item['Pandit Price'] = '_';
      }

      if (Array.isArray(item?.expenceseItem) && item?.expenceseItem?.length > 0) {
        const totalExpenses = item?.expenceseItem
          .map(expense => parseFloat(expense?.price || 0))
          .reduce((total, price) => total + price, 0);
      
        const panditPrice = parseFloat(item?.panditPrice || 0); 
        item["Total Price"] = (totalExpenses + panditPrice).toFixed(2); 
      }

      if (item?.totalBookingPrice) {
        item['Total Booking Price'] = item?.totalBookingPrice;
      } else {
        item['Total Booking Price'] = '_';
      }

      if (item?.totalPujaProfit) {
        item['Total Puja Profit'] = item?.totalPujaProfit;
      } else {
        item['Total Puja Profit'] = '_';
      }

      if (item?.createdAt) {
        item['Create date '] = moment(item?.createdAt).format('DD MMM, YYYY hh:mm A');
      }
      

      delete item?._id;
      delete item?.pujaId;
      delete item.panditId
      delete item?.panditName
      delete item?.panditPrice
      delete item?.updatedAt
      delete item?.userId
      delete item?.createdAt
      delete item?.expenceseItem;
      delete item?.totalPrice
      delete item?.totalBookingPrice
      delete item?.totalPujaProfit

    }
    let csv = Papa.unparse(data, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveBlob(csvData, 'UserList.csv');
    else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.download = 'Puja Expenses.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const DownloadFile = async () => {
    let selectedOptionId = selectedOption?.value === undefined ? '' : selectedOption?.value;
        const response = await APIServices.get(
          `pandit/getAllPujaExpenceseList?panditId=${selectedOptionId}&pujaId=${
            selectedPujaSec?.current
          }&startDate=${startDate ? formateDate(startDate) : ''}&endDate=${endDate ? formateDate(endDate) : ''}`,
          headers
        );

    if (response?.data?.status === true) {
        handleDownload(response.data?.Listdata);
    }
  };


  const handleFilterClick = () => {
    if (selectedOption?.value || selectedPujaSec?.current || startDate || endDate) {
      pujaPanditList(skip, limit, 0, selectedOption?.value, selectedPujaSec?.current, startDate, endDate);
    }
  };

  return (
    <>
      {
        permissioncontext.view?
        <div className='main aarti_section'>
        <>
          <div className='gita_quote_heading'>
            <div className='container d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q'>
                Puja Expense <small>({allDataCount})</small>
              </h4>
            </div>
          </div>
        </>
        <div className='user-list-table'>
          <div class='feed_area box-shadow br-6 p-20 my-3 font_16'>
            <div class='chat_search_box user-search'>
              <div className='chat-search-field'>
                <Form>
                  <Form.Group as={Row} className='align-items-end' controlId=''>
                    <Col md={4} className='mb-3'>
                      <Form.Label>Puja</Form.Label>
                      <Select
                        components={{ Input }}
                        autoComplete='new-password'
                        options={pujaOptions}
                        value={
                          selectedPujaSec.current === ''
                            ? ''
                            : pujaOptions.find(option => option.value === selectedPujaSec.current)
                        }
                        onChange={handleChangeSec}
                        classNamePrefix='react-select'
                        // className='fw-bold text-capitalize custom-datepicker'
                      />
                    </Col>
                    <Col md={4} className='mb-3'>
                      <Form.Label class='label-f-w-cd'>Select Pandit</Form.Label>
                      <Select
                        className=' mt-2'
                        classNamePrefix='react-select'
                        options={options}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        placeholder='Search by Pandit...'
                        isClearable
                      />
                    </Col>
                    <Col md={4} className='mb-3'>
                      <Form.Label className=''>Search By Created Date</Form.Label>
                      <div className='date-range-wrapper'>
                        <div className='select-icon-wrapper'>
                          <select
                            className='select-range'
                            value={selectedRange}
                            onChange={e => {
                              handleDateRange(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option value='disabled'>Select Date Range</option>
                            <option value='today'>Today</option>
                            <option value='yesterday'>Yesterday</option>
                            <option value='thisWeek'>This Week</option>
                            <option value='last7Days'>Last 7 Days</option>
                            <option value='thisMonth'>This Month</option>
                            <option value='lastMonth'>Last Month</option>
                            <option value='customDate'>Custom Date</option>
                          </select>
                          <img src='/images/down-arrow.png' className='select-icons' />
                        </div>

                        {selectedRange === 'customDate' && (
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            onChange={update => {
                              const [start, end] = update;
                              setStartDate(start);
                              setEndDate(end);
                              setSkip(0);
                            }}
                            // isClearable={true}
                            placeholderText='Select custom date range'
                            dateFormat='yyyy-MM-dd'
                          />
                        )}
                      </div>
                    </Col>
                    <Col md={4} className='mb-3'>
                      <Button
                        variant='secondary'
                        className='as_btn ms-0'
                        onClick={() => {
                          handleFilterClick();
                          setSkip(0);
                        }}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='secondary'
                        className='as_btn reset-btn'
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        Reset
                      </Button>
                      {adminUser?.adminType === "super_admin" ? 
                            (<Button
                              className='as_btn ms-2 download-btn'
                              variant='primary'
                              onClick={() => {
                                DownloadFile();
                              }}
                             >
                              Download File
                         </Button>) : "" }
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={pujaPanditList}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className='no_records'>Records not Available</h2>
        )}

        {/* expenses modal */}
        <Modal
          centered
          scrollable
          show={expensesModal}
          size='lg'
          // size={`${expensesData?.pujaExpencese === null} ? 'xl : 'lg`}
          aria-labelledby='example-modal-sizes-title-sm'
          backdrop='static'
          onHide={() => {
            setExpensesModal(false);
          }}
        >
          <Modal.Header className='modal-header-hd' closeButton>
            <Modal.Title>Expense</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className=''>
                  <ol className='puja-expenses-wrapper'>
                    <li className='d-flex justify-content-between'>
                      <p className='my-1 fw-semibold'>Expense</p>
                      <p className='mx-4  fw-semibold'>Price</p>
                    </li>
                    <hr/>
                    <li className='d-flex justify-content-between'>
                        <p className='my-1 fw-semibold'>{expensesData?.panditName} (Pandit Name)</p>
                        {/* <p className='mx-4'>₹{expensesData?.panditPrice}.00</p> */}
                        <p className="mx-4">
                            ₹{expensesData?.panditPrice ? parseFloat(expensesData.panditPrice).toFixed(2) : "0.00"}
                        </p>

                    </li>
                    {expensesData && expensesData?.expenceseItem?.length>0 &&
                      expensesData?.expenceseItem?.map((data, ind) => {
                        return (
                          <>
                            <li className='d-flex justify-content-between' key={data?._id}>
                              <div className='d-flex align-items-center'>
                                  { data?.name !=="" ? (ind + 1)+"." :""}
                                  <p className='my-1 ms-1'>{data?.name !=="" ? data?.name : ""}</p>
                                </div>
                              {/* <p className='mx-4'>{data?.price !=="" ? ("₹"+(data?.price)+".00") : ""}</p> */}
                              <p className='mx-4'>{data?.price !=="" ? ("₹"+(data?.price ? parseFloat( data?.price).toFixed(2) : "0.00")) : ""}</p>
                            </li>
                          </>
                        );
                      })}
                      
                      <hr/>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-semibold my-1'>Total Expense Price</p>
                        {/* <p className='mx-4'>₹{expensesData?.totalPrice}.00</p> */}
                        <p className='mx-4'>₹{expensesData?.totalPrice ? parseFloat(expensesData?.totalPrice).toFixed(2) : "0.00"}</p>
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-semibold my-1'>Total Booking Price</p>
                        {/* <p className='mx-4'>₹{totalBookingPrice}.00</p> */}
                        <p className='mx-4'>₹{totalBookingPrice ? parseFloat(totalBookingPrice).toFixed(2) : "0.00"}</p>
                      </div>
                      <hr/>
                      <div className='d-flex justify-content-between'>
                      <p className='fw-semibold my-1'>
                        {Number(totalBookingPrice) - Number(expensesData?.totalPrice) >= 0 
                          ? "Total Puja Profit" 
                          : "Total Puja Loss"}
                      </p>
                      {/* <p className='mx-4'>
                        ₹{Math.abs(Number(totalBookingPrice) - Number(expensesData?.totalPrice))}.00
                      </p> */}
                      <p className='mx-4'>
                        ₹{parseFloat(Math.abs(Number(totalBookingPrice) - Number(expensesData?.totalPrice))).toFixed(2)}
                      </p>
                    </div>

                  </ol>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              className='reset-btn'
              onClick={() => {
                setExpensesModal(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <ToastContainer /> */}
      </div>:
     <Notauthorized />
      }
     
    </>
  );
};

export default PujaExpensesList;
